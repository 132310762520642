<form [formGroup]="form" (ngSubmit)="onAddProduct()">
  <div class="row align-items-center justify-content-around">
    <div class="col-2">
      <mat-form-field appearance="standard" style="width: 100%">
        <mat-label> {{ "SEARCH_GLOBAL.OE_NUMBER" | translate }}</mat-label>
        <input
          matInput
          formControlName="oeNumber"
          (keyup)="searchOeNum()"
          [ngClass]="{
            'text-success': +form.value.class === 1,
            'text-danger': +form.value.class === 3,
            'text-warning': +form.value.class === 2
          }"
        />
      </mat-form-field>

    </div>
    <div class='col-2'>
      <mat-checkbox
        formControlName="selfCheck"
        (change)="setCheck()"
        color="primary"
        disabled="true"
      ><small>{{ "SEARCH_GLOBAL.SELF_ADD" | translate }}</small></mat-checkbox
      >
    </div>
    <div class="col-2 px-1">
      <mat-form-field appearance="standard" style="width: 100%">
        <mat-label> {{ "SEARCH_GLOBAL.ORDER_LIST" | translate }}</mat-label>
        <mat-select formControlName="order">
          <mat-option *ngFor="let item of orderList" [value]="item.value">{{
            item.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-2">
      <button mat-button class="btn btn-success" type="submit">
        {{ "SEARCH_GLOBAL.ADD_TO_ORDER" | translate }}
      </button>
    </div>

    <div class="col-2">
      <mat-form-field appearance="standard" style="width: 100%">
        <mat-label>Кол-во</mat-label>
        <mat-select formControlName="count">
          <mat-option *ngFor="let item of countList" [value]="item">{{
            item
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</form>
