<mat-card-title>
  <div class="row justify-content-end mb-3">
    <div class="col">Изменить исполнителя</div>
    <div class="col-auto">
      <button
        type="button"
        mat-icon-button
        class="btn btn-primary btn-sm"
        matTooltip="Закрыть"
        matTooltipPosition="left"
        matTooltipClass="tooltip"
        matDialogClose
      >
        <img src="assets/img/icon/close/Icon_Close.svg" alt="close" />
      </button>
    </div>
  </div>
</mat-card-title>

<mat-card-content>
  <mat-form-field
    appearance="outline"
    style="width: 100%"
    *ngIf="workmanList$ | async as workmanList"
  >
    <mat-select [formControl]="workmanListForm">
      <mat-option *ngFor="let item of workmanList" [value]="item.value">{{
        item.text
      }}</mat-option>
    </mat-select>
  </mat-form-field>
</mat-card-content>
<mat-card-actions align="center">
  <button mat-button class="btn btn-success" (click)="onSetChangeWorkmanId()">
    Сохранить
  </button>
</mat-card-actions>
