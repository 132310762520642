import { NgModule } from '@angular/core';
import { HeaderComponent } from './component/header/header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { OrderIndicatorModule } from './component/order-indicator/order-indicator.module';
import { TaskIndicatorModule } from './component/task-indicator/task-indicator.module';
import {
  _MatMenuDirectivesModule,
  MatMenuModule,
} from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CatalogListsModule } from './component/catalog-lists/catalog-lists.module';
import { PeersListComponent } from './component/peers-list/peers-list.component';
import { MatBadgeModule } from '@angular/material/badge';
import { SearchModule } from './component/search/search.module';
import { HeaderDopSaleModule } from './dialog/header-dop-sale/header-dop-sale.module';
import { HeaderShieldInfoModule } from './dialog/header-shield-info/header-shield-info.module';
import { HeaderInfoModule } from './dialog/header-info/header-info.module';
import { HeaderSearchUserModule } from './dialog/header-search-user/header-search-user.module';
import { HeaderTaskIndicatorListModule } from './dialog/header-task-indicator-list/header-task-indicator-list.module';

@NgModule({
  declarations: [HeaderComponent, PeersListComponent],
  exports: [HeaderComponent],
  imports: [
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    CommonModule,
    RouterModule,
    OrderIndicatorModule,
    TaskIndicatorModule,
    _MatMenuDirectivesModule,
    MatMenuModule,
    MatTooltipModule,
    CatalogListsModule,
    MatBadgeModule,
    SearchModule,
    HeaderDopSaleModule,
    HeaderShieldInfoModule,
    HeaderInfoModule,
    HeaderSearchUserModule,
    HeaderTaskIndicatorListModule,
  ],
})
export class HeaderModule {}
