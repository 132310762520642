<div>
  <mat-toolbar color="primary">
    <button mat-icon-button (click)="sNav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <div class="text-center ml-3" style="width: 8rem">
      <lib-order-indicator [orderList]="orderList"></lib-order-indicator>
    </div>
    <div class="text-center ml-3 d-md-block d-none" style="width: 18rem">
      <lib-task-indicator
        [taskIndicator]="taskIndicator"
        (showIndicator)="showTaskIndicator()"
      ></lib-task-indicator>
    </div>
    <div class="text-center ml-3 d-md-block d-none" style="width: 4rem">
      <button
        mat-button
        class="btn-block"
        matTooltip="Создать клиента"
        matTooltipClass="tooltip"
        (click)="createNewClient()"
      >
        <mat-icon>person_add</mat-icon>
      </button>
    </div>
    <div class="text-center ml-3 d-md-block d-none" style="width: 4rem">
      <lib-catalog-lists (showShield)="showShield($event)"></lib-catalog-lists>
    </div>

    <div class="text-center ml-3 d-md-block d-none" style="width: 4rem">
      <button
        mat-button
        class="btn-block"
        matTooltip="Допродажи"
        matTooltipClass="tooltip"
        (click)="showMoreSale()"
      >
        <mat-icon>price_change</mat-icon>
      </button>
    </div>
    <div class="text-center ml-3" style="width: 8rem">
      <lib-peers-list [peersInfo]="peersInfo"></lib-peers-list>
    </div>

    <div class="d-md-block d-none" style="width: 16rem; margin-left: auto">
      <lib-search></lib-search>
    </div>
    <div class="text-center d-md-block d-none" style="margin-left: auto">
      <button
        mat-button
        class="btn-block"
        (click)="showHelpInfo()"
        matTooltip="Инструкция по работе"
        matTooltipClass="tooltip"
      >
        <mat-icon>help</mat-icon>
      </button>
    </div>

    <div style="margin-left: auto" class="d-block d-md-none">
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        [matBadge]="
          taskIndicator.countTask > 0 ||
          taskIndicator.requestCount > 0 ||
          taskIndicator.randomProduct > 0
            ? '+'
            : ''
        "
        matBadgePosition="after"
        matBadgeColor="warn"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
  </mat-toolbar>

  <mat-sidenav-container [hasBackdrop]="true">
    <mat-sidenav #sNav [mode]="'side'">
      <mat-nav-list *ngIf="loadMenu$ | async as loadMenu">
        <span *ngFor="let item of loadMenu">
          <span [ngSwitch]="item.type">
            <a
              *ngSwitchCase="1"
              mat-list-item
              [routerLink]="item.link"
              (click)="sNav.toggle()"
              style="height: 36px"
            >
              <mat-icon mat-list-icon>{{ item.icon }}</mat-icon>
              <div mat-line>{{ item.text }}</div>
            </a>
            <mat-divider *ngSwitchCase="2"></mat-divider>
            <div *ngSwitchCase="3" mat-subheader>{{ item.heading }}</div>
          </span>
        </span>

        <a
          mat-list-item
          [routerLink]="['/login']"
          (click)="sNav.toggle(); setLogOut()"
          style="height: 36px"
        >
          <mat-icon mat-list-icon>logout</mat-icon>
          <div mat-line>Выйти</div>
        </a>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content style="min-height: 600px">
      <!-- <app-notification></app-notification>-->
      <ng-content></ng-content>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<mat-menu #menu="matMenu" style="width: 210px !important">
  <div mat-menu-item>
    <lib-task-indicator
      [taskIndicator]="taskIndicator"
      (showIndicator)="showTaskIndicator()"
    ></lib-task-indicator>
  </div>
  <div mat-menu-item>
    <button mat-button class="btn-block" (click)="createNewClient()">
      Создать клиента
    </button>
  </div>
  <div mat-menu-item>
    <lib-catalog-lists (showShield)="showShield($event)"></lib-catalog-lists>
  </div>
  <div mat-menu-item>
    <button
      mat-button
      class="btn-block"
      matTooltip="Допродажи"
      matTooltipClass="tooltip"
      (click)="showMoreSale()"
    >
      Допродажи
    </button>
  </div>
  <div mat-menu-item>
    <lib-search></lib-search>
  </div>
  <div mat-menu-item>
    <button
      mat-button
      class="btn-block"
      (click)="showHelpInfo()"
      matTooltip="Инструкция по работе"
      matTooltipClass="tooltip"
    >
      Инструкция
    </button>
  </div>
</mat-menu>
