import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderRandomProductComponent } from './header-random-product.component';
import { TitleModalWindowModule } from '../../shared/title-modal-window/title-modal-window.module';
import { MatCardModule } from '@angular/material/card';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [HeaderRandomProductComponent],
  imports: [
    CommonModule,
    TitleModalWindowModule,
    MatCardModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatButtonModule,
  ],
})
export class HeaderRandomProductModule {}
