<div
  style="
    position: absolute;
    background: rgba(227, 227, 227, 0.5);
    width: 100%;
    height: 1000vh;
    top: 0;
    left: 0;
    z-index: 50000;
  "
  class="text-center"
>
  <mat-card
    style="
      width: 30rem;
      margin: auto;
      position: fixed;
      left: 0;
      right: 0;
      top: 35%;
    "
  >
    <mat-card-title *ngIf="title">{{ title }}</mat-card-title>
    <mat-card-subtitle *ngIf="subtitle">{{ subtitle }}</mat-card-subtitle>
    <mat-card-content>
      <div class="d-flex justify-content-center my-5">
        <mat-spinner diameter="25"></mat-spinner>
      </div>
    </mat-card-content>
  </mat-card>
</div>
