import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../../../core/services/api.service';
import {OtherSpr, RequestBody} from '../../../core/interfaces/interfaces';
import {Sud} from './sud.interface';

@Injectable()
export class SudService {

  constructor(
    private api: ApiService
  ) {
  }

  onLoadStrUnitList(): Observable<Sud[]> {
    const q: RequestBody = {
      modelName: 'StructureUnit',
      calledMethod: 'onLoadStructUnitList',
      methodProperties: {
        type: 1
      }
    };
    return this.api.post_jwt(q);
  }

  onLoadPayData(dateStart, endDate, sudId, returnProd): Observable<Sud[]> {
    const q: RequestBody = {
      modelName: 'StructureUnit',
      calledMethod: 'onLoadPayData',
      methodProperties: {
        type: 1,
        dateStart,
        endDate,
        sudId,
        returnProd
      }
    };
    return this.api.post_jwt(q);
  }

  onLoadPayDataDelivery(dateStart, endDate, sudId, returnProd): Observable<Sud[]> {
    const q: RequestBody = {
      modelName: 'StructureUnit',
      calledMethod: 'onLoadPayDataDelivery',
      methodProperties: {
        type: 1,
        dateStart,
        endDate,
        sudId,
        returnProd,
      }
    };
    return this.api.post_jwt(q);
  }

  onloadOrderInfo(dateStart, endDate, sudId, filter, typeFilter): Observable<Sud[]> {
    const q: RequestBody = {
      modelName: 'StructureUnit',
      calledMethod: 'onloadOrderInfo',
      methodProperties: {
        type: 1,
        dateStart,
        endDate,
        sudId,
        filter,
        typeFilter
      }
    };
    return this.api.post_jwt(q);
  }

  onCreateNewSU(): Observable<any> {
    const q: RequestBody = {
      modelName: 'StructureUnit',
      calledMethod: 'onCreateNewStrUnit',
      methodProperties: {
        type: 1
      }
    };
    return this.api.post_jwt(q);
  }

  // Сохранить изменения в СЕ
  saveDataSU(formData: object): Observable<any> {
    const q: RequestBody = {
      modelName: 'StructureUnit',
      calledMethod: 'saveDataSU',
      methodProperties: {
        type: 1,
        ...formData
      }
    };
    return this.api.post_jwt(q);
  }

  // Работа с платежными данными
  addNewPayment(pay, value, def, suId): Observable<any> {
    const q: RequestBody = {
      modelName: 'StructureUnit',
      calledMethod: 'addNewPayment',
      methodProperties: {
        type: 1,
        pay,
        value,
        def,
        suId
      }
    };
    return this.api.post_jwt(q);
  }

  deletePayment(id): Observable<any> {
    const q: RequestBody = {
      modelName: 'StructureUnit',
      calledMethod: 'deletePayment',
      methodProperties: {
        type: 1,
        id
      }
    };
    return this.api.post_jwt(q);
  }

  setDefaultPayment(id, typeSud, suId): Observable<any> {
    const q: RequestBody = {
      modelName: 'StructureUnit',
      calledMethod: 'setDefaultPayment',
      methodProperties: {
        type: 1,
        id,
        typeSud,
        suId,
      }
    };
    return this.api.post_jwt(q);
  }

  // Работа с АПИ ключами перевозчиков
  onLoadDeliveryList(): Observable<OtherSpr[]> {
    const q: RequestBody = {
      modelName: 'StructureUnit',
      calledMethod: 'loadDeliveryList',
      methodProperties: {
        type: 1
      }
    };
    return this.api.post_jwt(q);
  }

  addNewDelivery(delivery, value, dateCreate, dateEnd, def, suId): Observable<any> {
    const q: RequestBody = {
      modelName: 'StructureUnit',
      calledMethod: 'addNewDeliveryKey',
      methodProperties: {
        type: 1,
        delivery,
        value,
        dateCreate,
        dateEnd,
        def,
        suId
      }
    };
    return this.api.post_jwt(q);
  }

  deleteDelivery(id): Observable<any> {
    const q: RequestBody = {
      modelName: 'StructureUnit',
      calledMethod: 'deleteDelivery',
      methodProperties: {
        type: 1,
        id
      }
    };
    return this.api.post_jwt(q);
  }

  setDefaultDelivery(id, devId, suId): Observable<any> {
    const q: RequestBody = {
      modelName: 'StructureUnit',
      calledMethod: 'setDefaultDelivery',
      methodProperties: {
        type: 1,
        id,
        devId,
        suId,
      }
    };
    return this.api.post_jwt(q);
  }

}
