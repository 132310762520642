<mat-card-title>
  <div class="row justify-content-between mb-3">
    <div class="col-auto h2"><b>{{dialogData.katalNumber}} {{dialogData.brand}}</b></div>
    <div class="col-auto">
      <button type="button" mat-icon-button class="btn btn-primary btn-sm"
              matTooltip="Закрыть"
              matTooltipPosition="left"
              matTooltipClass="tooltip"
              matDialogClose>
        <img src="assets/img/icon/close/Icon_Close.svg" alt="close">
      </button>
    </div>
  </div>
</mat-card-title>

<table mat-table [dataSource]="dialogData.criList" class="table noHeader dense-md table-striped table-hover ">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element" class="h5"><b>{{element.name}}</b></td>
  </ng-container>
  <ng-container matColumnDef="value">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element" class="h5" style="color:#808080 "> {{element.value}} </td>
  </ng-container>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
