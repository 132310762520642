<div class="ntf-container">
  <lib-message
    *ngFor="let item of ntfList; let i = index"
    [toast]="item"
    [id]="item.id"
    [i]="+i"
    [btnFunk]="item.btnFunk"
    [hash]="item.hashBtn"
    (remove)="remove($event)"
  ></lib-message>
</div>
