<div class="container-fluid my-3">
  <div class="row">
    <div class="col">
      <mat-card>
        <mat-card-title>Зарегестрированные пользователи</mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content class="pt-3">
          <form [formGroup]="form" id="filterForm" (ngSubmit)="getOrderInfo()">
            <div class="row align-items-center">
              <div class="col-12 col-md-3">
                <mat-form-field>
                  <mat-label>Диапазон</mat-label>
                  <mat-date-range-input [rangePicker]="picker">
                    <input
                      matStartDate
                      formControlName="start"
                      placeholder="Дата начала">
                    <input matEndDate formControlName="end" placeholder="Дата конца">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker (closed)="getDynamicSpr()" #picker></mat-date-range-picker>
                </mat-form-field>
              </div>
              <div class="col-auto">
                <button mat-button type="submit" class="btn btn-success" form="filterForm">Отправить</button>
              </div>
            </div>
          <!--  <div class="row" *ngIf="(vm$| async) as vm;else showContent">
              <div class="col-12 col-md-3">
                <div class="row align-items-center">
                  <div class="col-1 text-center">
                    <input type="radio" form="filterForm" formControlName="typeFilter" value="1" >
                  </div>
                  <div class="col-auto">
                    <mat-form-field>
                      <mat-label>Источник</mat-label>
                      <mat-select formControlName="source" multiple>
                        <mat-option #allSelectedSource (click)="toggleAllSource()" [value]="'all'">Выбрать все</mat-option>
                        <mat-divider></mat-divider>
                        <mat-option *ngFor="let spr of vm.sourceList" [value]="spr.value">
                          {{spr.text}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-3">
                <div class="row align-items-center">
                  <div class="col-1 text-center">
                    <input type="radio" form="filterForm" formControlName="typeFilter"  value="3">
                  </div>
                  <div class="col-auto">
                    <mat-form-field>
                      <mat-label>Автомобиль</mat-label>
                      <mat-select formControlName="car" multiple>
                        <mat-option #allSelectedCar (click)="toggleAllCar()" [value]="'all'">Выбрать все</mat-option>
                        <mat-divider></mat-divider>
                        <mat-option *ngFor="let spr of vm.carList" [value]="spr.value">
                          {{spr.text}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-3">
                <div class="row align-items-center">
                  <div class="col-1 text-center">
                    <input type="radio" form="filterForm" formControlName="typeFilter"  value="4">
                  </div>
                  <div class="col-auto">
                    <mat-form-field>
                      <mat-label>Способ обращения</mat-label>
                      <mat-select formControlName="formSale" multiple>
                        <mat-option #allSelectedForm (click)="toggleAllForm()" [value]="'all'">Выбрать все</mat-option>
                        <mat-divider></mat-divider>
                        <mat-option *ngFor="let spr of vm.formList" [value]="spr.value">
                          {{spr.text}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <ng-template #showContent>
              <div class="d-flex justify-content-center my-5">
                <mat-spinner diameter="25"></mat-spinner>
              </div>
            </ng-template>-->
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="row my-3">
    <div class="col">
      <p-table
        *ngIf="(clientList$|async) as clientList;"
        [columns]="clientList.headerList"
        [value]="clientList.clientList"
        [rowsPerPageOptions]="[10,25,50,100,500]"
        [paginator]="true"
        currentPageReportTemplate="Элементов на странице ({first} - {last}) з {totalRecords} "
        [filterDelay]="0"
        autoLayout="true"
        [rows]="10"
        selectionMode="single"
        styleClass="p-datatable-gridlines p-datatable-sm">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th
              *ngFor="let col of columns"
              [pSortableColumn]="col.field"
              style="font-weight: 400;font-size: 12px">
              <p-sortIcon [field]="col.field"></p-sortIcon>
              {{col.header}}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td
              *ngFor="let col of columns; let index = index"
              style="font-weight: 300;font-size: 12px"
            >
                <span *ngIf="col.field=='successOrder'; else text2">
                  {{rowData[col.field]}}|<span matTooltip="Одинарный заказ" matTooltipClass="tooltip"
                                               style="color: #2F80EC">
                  {{rowData['oneProd']}}
                  ({{(rowData['oneProd']*100/rowData[col.field])| number:'1.0-0'}})
                </span>/<span
                  matTooltip="Комплексный заказ" matTooltipClass="tooltip"
                  style="color:#27AE60">
                  {{rowData['moreProd']}}
                  ({{(rowData['moreProd']*100/rowData[col.field])| number:'1.0-0'}})
                  </span>
                </span>
                <ng-template #text2>
                  <span *ngIf="col.field=='clientCard';else text3">
                    <a *ngIf="rowData['clientCard']" [href]="'https://srv.avto100.in.ua/crm-avto100/userNewCart?var='+rowData['clientCard']" target="_blank"><mat-icon>account_box</mat-icon></a>
                  </span>
                  <ng-template #text3>
                    {{rowData[col.field]}}
                  </ng-template>

                </ng-template>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <ng-template *ngIf="loadingClientTable">
        <div class="d-flex justify-content-center my-5">
          <mat-spinner diameter="25"></mat-spinner>
        </div>
      </ng-template>

    </div>
  </div>

</div>
