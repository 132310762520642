<lib-title-modal-window [title]="title"></lib-title-modal-window>
<mat-card-content>
  <form [formGroup]="form">
    <mat-card-content>
      <div class="row">
        <div class="col-12 col-md-4">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label> Телефон</mat-label>
            <input
              matInput
              type="text"
              prefix="+38"
              [mask]="' (000) 000-00-99'"
              formControlName="phone"
              (keyup)="searchInfo()"
              (input)="searchInfo()"
            />
          </mat-form-field>
        </div>
        <div class="col-12 col-md-4">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label> ФИО клиента</mat-label>
            <input
              matInput
              type="text"
              formControlName="name"
              (keyup)="searchInfo()"
              (input)="searchInfo()"
            />
            <mat-error *ngIf="form.get('name').hasError('pattern')">
              Разрешены только кирилические символы
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 col-md-4">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label> ФИО получателя</mat-label>
            <input
              matInput
              type="text"
              formControlName="nameRecipient"
              (keyup)="searchInfo()"
              (input)="searchInfo()"
              (focus)="clearData()"
            />
            <mat-error *ngIf="form.get('nameRecipient').hasError('pattern')">
              Разрешены только кирилические символы
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 col-md-4">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label> Номер заказа</mat-label>
            <input
              matInput
              formControlName="orderNumber"
              (keyup)="searchInfo()"
              (input)="searchInfo()"
              (focus)="clearData()"
            />
          </mat-form-field>
        </div>
        <div class="col-12 col-md-4">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label> Номер ТТН</mat-label>
            <input
              matInput
              formControlName="orderTtn"
              (keyup)="searchInfo()"
              (input)="searchInfo()"
              (focus)="clearData()"
            />
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
  </form>

  <div *ngIf="getSearchInfo$ | async as getSearchInfo; else showCreateBtn">
    <div class="row" *ngFor="let item of getSearchInfo">
      <div class="col">
        <a [href]="item.value" class="link">{{ item.data }}</a>
      </div>
    </div>
  </div>
  <ng-template #showCreateBtn>
    <button (click)="createNewClient()" mat-button class="btn btn-success">
      Создать клиента
    </button>
  </ng-template>
</mat-card-content>
