<button mat-button [matMenuTriggerFor]="peers" class="btn-block">
  <mat-icon>support_agent</mat-icon>
  <span
    class="btn btn-danger px-2 ml-3"
    style="display: inline-block; font-size: 20px !important"
  >
    {{ peersInfo.countPeers }} ({{ peersInfo.countPeersBusy }})</span
  >
</button>
<mat-menu #peers="matMenu" style="width: 280px !important">
  <button mat-menu-item type="button" *ngFor="let item of peersInfo.peers">
    <div class="row" style="width: 280px !important">
      <div class="col-9">{{ item.manager }} - {{ item.phone }}</div>
      <div class="col-3 mt-2" style="font-size: 20px !important">
        <div
          [matTooltip]="'Операторы'"
          matTooltipClass="tooltip"
          style="width: 25px; height: 25px; border-radius: 50%"
          [ngClass]="{
            'bg-gray': +item.statusId === 0,
            'bg-success': +item.statusId === 1,
            'bg-warning': +item.statusId === 2,
            'bg-danger': +item.statusId === 3
          }"
        ></div>
      </div>
    </div>
  </button>
</mat-menu>
