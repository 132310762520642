<p-table *ngIf="(list$| async) as list"
  [columns]="list.cols"
         [value]="list.rows"
         [rowsPerPageOptions]="[10,25,50,100,500]"
         [paginator]="true"
         currentPageReportTemplate="Элементов на странице ({first} - {last}) з {totalRecords} "
         [filterDelay]="0"
         autoLayout="true"
         [rows]="10"
         selectionMode="single"
         styleClass="p-datatable-gridlines p-datatable-sm">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th
        *ngFor="let col of columns"
        [pSortableColumn]="col.field"
        style="font-weight: 400;font-size: 12px">
        <p-sortIcon [field]="col.field"></p-sortIcon>
        {{col.header}}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr [class]="rowData['class']">
      <td
        *ngFor="let col of columns;"
        style="font-weight: 300;font-size: 12px"
      >
        <span *ngIf="col.field==='createDate';else text">
          {{rowData[col.field]|date : 'dd.MM.yyyy HH:mm'}}
        </span>
        <ng-template #text>
        <span *ngIf="col.field==='zakaz';else text2">
          <a [href]="'https://srv.avto100.in.ua/crm/sale-card/z/zakaz/'+rowData[col.field]" target="_blank">{{rowData[col.field]}}</a>
        </span>
        </ng-template>
        <ng-template #text2>
          {{rowData[col.field]}}
        </ng-template>



      </td>
    </tr>
  </ng-template>
</p-table>
