import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddRecommendationOnNameComponent } from '@crm/search/product-card/dialog/add-recomendation-on-name/add-recommendation-on-name.component';
import { MaterialModule } from '@core/modules/material-module';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [AddRecommendationOnNameComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatTooltipModule,
    MatDialogModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatProgressSpinnerModule,
  ],
})
export class AddRecommendationOnNameModule {}
