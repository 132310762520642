<div *ngIf="loadSummary$ |async as loadSummary">
  <div *ngFor="let item of loadSummary" class="my-3">
    <p-table [columns]="item.cols"
             [value]="item.rows"
             [loading]="loading"
             autoLayout="true"
             selectionMode="single"
             styleClass="p-datatable-gridlines p-datatable-sm">
      <ng-template pTemplate="caption">
        {{item.name}}
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th
            *ngFor="let col of columns"
            style="font-weight: 400;font-size: 12px">
            <p-sortIcon [field]="col.field"></p-sortIcon>
            {{col.header}}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [class]="rowData['class']">
          <td
            *ngFor="let col of columns;"
            style="font-weight: 300;font-size: 12px"
          >

            {{rowData[col.field]}}


          </td>
        </tr>
      </ng-template>


    </p-table>

  </div>
</div>
