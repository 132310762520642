import { Injectable } from '@angular/core';
import { ApiService } from '../../../../core/services/api.service';
import { Observable } from 'rxjs';
import { OtherSpr, RequestBody } from '../../../../core/interfaces/interfaces';
import { PretensionInfo, TaskComment, TaskInfo } from './task';

@Injectable()
export class TaskService {
  constructor(private api: ApiService) {}

  loadTypeTask(): Observable<OtherSpr[]> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprOtherSpr',
      methodProperties: {
        type: 1,
        listId: 26,
      },
    };
    return this.api.post_jwt(q);
  }

  loadManagerTask(): Observable<OtherSpr[]> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprManager',
      methodProperties: {
        type: 1,
        managerList: 4,
      },
    };
    return this.api.post_jwt(q);
  }

  loadReasonList(orderNumber): Observable<OtherSpr[]> {
    const q: RequestBody = {
      modelName: 'Task',
      calledMethod: 'getReasonList',
      methodProperties: {
        type: 1,
        orderNumber,
      },
    };
    return this.api.post_jwt(q);
  }

  createNewTask(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Task',
      calledMethod: 'onCreateNewTask',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  loadProductList(order): Observable<any> {
    const q: RequestBody = {
      modelName: 'Task',
      calledMethod: 'getProductToPretension',
      methodProperties: {
        type: 1,
        order,
      },
    };
    return this.api.post_jwt(q);
  }

  onSetChangeWorkmanId(manager, taskId): Observable<any> {
    const q: RequestBody = {
      modelName: 'Task',
      calledMethod: 'onSetChangeWorkmanId',
      methodProperties: {
        type: 1,
        manager,
        taskId,
      },
    };
    return this.api.post_jwt(q);
  }

  createPretension(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Task',
      calledMethod: 'onCreatePretension',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  loadTaskInfo(id): Observable<TaskInfo> {
    const q: RequestBody = {
      modelName: 'Task',
      calledMethod: 'loadTaskInfo',
      methodProperties: {
        type: 1,
        id,
      },
    };
    return this.api.post_jwt(q);
  }
  loadPretensionInfo(id): Observable<PretensionInfo> {
    const q: RequestBody = {
      modelName: 'Task',
      calledMethod: 'loadPretensionInfo',
      methodProperties: {
        type: 1,
        id,
      },
    };
    return this.api.post_jwt(q);
  }

  loadTaskComment(id): Observable<TaskComment[]> {
    const q: RequestBody = {
      modelName: 'Task',
      calledMethod: 'loadTaskComment',
      methodProperties: {
        type: 1,
        id,
      },
    };
    return this.api.post_jwt(q);
  }

  loadCategory(): Observable<OtherSpr[]> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprOtherSpr',
      methodProperties: {
        type: 1,
        listId: 26,
      },
    };
    return this.api.post_jwt(q);
  }

  loadReason(): Observable<OtherSpr[]> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprOtherSpr',
      methodProperties: {
        type: 1,
        listId: 28,
      },
    };
    return this.api.post_jwt(q);
  }

  getReturnReason(reason): Observable<OtherSpr[]> {
    const q: RequestBody = {
      modelName: 'Task',
      calledMethod: 'getReturnReason',
      methodProperties: {
        type: 1,
        reason,
      },
    };
    return this.api.post_jwt(q);
  }

  loadAltOrder(hash): Observable<OtherSpr[]> {
    const q: RequestBody = {
      modelName: 'Task',
      calledMethod: 'loadAltOrder',
      methodProperties: {
        type: 1,
        hash,
      },
    };
    return this.api.post_jwt(q);
  }

  loadAltProduct(order): Observable<any> {
    const q: RequestBody = {
      modelName: 'Task',
      calledMethod: 'loadAltProduct',
      methodProperties: {
        type: 1,
        order,
      },
    };
    return this.api.post_jwt(q);
  }

  setTaskToWork(id): Observable<any> {
    const q: RequestBody = {
      modelName: 'Task',
      calledMethod: 'setTaskToWork',
      methodProperties: {
        type: 1,
        id,
      },
    };
    return this.api.post_jwt(q);
  }

  setTaskToArchive(id): Observable<any> {
    const q: RequestBody = {
      modelName: 'Task',
      calledMethod: 'setTaskToArchive',
      methodProperties: {
        type: 1,
        id,
      },
    };
    return this.api.post_jwt(q);
  }

  addComment(id: number, comment: string): Observable<any> {
    const q: RequestBody = {
      modelName: 'Task',
      calledMethod: 'addComment',
      methodProperties: {
        type: 1,
        id,
        comment,
      },
    };
    return this.api.post_jwt(q);
  }

  confirmTask(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Task',
      calledMethod: 'confirmTask',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }
  changeDateTask(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Task',
      calledMethod: 'changeDateTask',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  onSaveChecked(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Task',
      calledMethod: 'onSaveChecked',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  onSaveDecision(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Task',
      calledMethod: 'onSaveDecision',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  onDismissPretension(id): Observable<any> {
    const q: RequestBody = {
      modelName: 'Task',
      calledMethod: 'onDismissPretension',
      methodProperties: {
        type: 1,
        id,
      },
    };
    return this.api.post_jwt(q);
  }

  onConfirmPretension(id): Observable<any> {
    const q: RequestBody = {
      modelName: 'Task',
      calledMethod: 'onConfirmPretension',
      methodProperties: {
        type: 1,
        id,
      },
    };
    return this.api.post_jwt(q);
  }
}
