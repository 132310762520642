<mat-card-title>
  <div class="row justify-content-between mb-3">
    <div class="col-auto align-self-center">
      Создать ТТН
      <button
        type="button"
        (click)="saveSelfTtn()"
        mat-button
        class="btn btn-round mr-2 btn-primary"
        matTooltip="Сохранить созданную ТТН"
        matTooltipClass="tooltip"
      >
        <mat-icon>send</mat-icon>
      </button>
    </div>
    <div class="col-auto">
      <button
        type="button"
        mat-icon-button
        class="btn btn-primary btn-sm"
        matTooltip="Закрыть"
        matTooltipPosition="left"
        matTooltipClass="tooltip"
        matDialogClose
      >
        <img src="assets/img/icon/close/Icon_Close.svg" alt="close" />
      </button>
    </div>
  </div>
</mat-card-title>
<div *ngIf="ttnInfo$ | async; else loading">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-12 col-md-3 my-2">
        <mat-card class="card-box mb-2" *ngIf="createTtnInfo.sendComment">
          <mat-card-title> Комментарий к отгрузке</mat-card-title>
          <div class="row my-2 py-3">
            <div class="col-4">{{ createTtnInfo.sendComment }}</div>
          </div>
          <div class="row">
            <div class="col">
              <mat-checkbox
                color="primary"
                #check1
                (change)="
                  check1.checked
                    ? (checkComment = true)
                    : (checkComment = false)
                "
                >Ознакомился
              </mat-checkbox>
            </div>
          </div>
        </mat-card>

        <mat-card class="card-box">
          <mat-card-title>
            Заказы {{ dialogData.orderList.join(", ") }}</mat-card-title
          >
          <mat-card-content>
            <button
              mat-button
              class="btn btn-primary ml-4"
              (click)="printCheck()"
            >
              Печать чеков
            </button>
            <div
              class="row mt-2 pb-2"
              style="border-bottom: 1px solid #cecece"
              *ngFor="let item of createTtnInfo.orderList"
            >
              <div class="col-12 pb-2">{{ item.nomen }}</div>
              <div class="col-5">{{ item.katalNumber }}</div>
              <div class="col-5">{{ item.brand }}</div>
              <div class="col-2">{{ item.countProd }}</div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-12 col-md-4 my-2">
        <mat-card class="card-box">
          <mat-card-subtitle> Отправитель Avto100.in.ua</mat-card-subtitle>
          <mat-card-content>
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>Контактное лицо отправителя</mat-label>
              <mat-select formControlName="sender">
                <mat-option
                  *ngFor="let item of createTtnInfo.senderList"
                  [value]="item.ref"
                  >{{ item.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>Отделение отправки</mat-label>
              <mat-select formControlName="senderWarehouse">
                <mat-option
                  *ngFor="let item of createTtnInfo.warehouseList"
                  [value]="item.ref"
                  >{{ item.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>Дата отправки</mat-label>
              <input
                matInput
                [matDatepicker]="picker1"
                formControlName="dateSend"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker1"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          </mat-card-content>
        </mat-card>

        <mat-card class="card-box my-2">
          <mat-card-subtitle> Получатель</mat-card-subtitle>

          <mat-card-content>
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>Контактное лицо отправителя</mat-label>
              <input matInput formControlName="recipient" />
            </mat-form-field>

            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>Телефон получателя</mat-label>
              <input
                matInput
                type="text"
                prefix="+38"
                [mask]="' (000) 000-00-99'"
                formControlName="recipientPhone"
              />
            </mat-form-field>

            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>Отделение получателя</mat-label>
              <input matInput formControlName="warehouseName" />
            </mat-form-field>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-12 col-md-5">
        <mat-card class="card-box my-2">
          <mat-card-subtitle> Параметры груза</mat-card-subtitle>

          <mat-card-content>
            <div
              *ngFor="let item of settlementSet; let index = index"
              style="border-bottom: 1px solid #cecece"
              class="pb-2 mb-4"
            >
              <div class="row">
                <div class="col-12 col-md-3">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label>Вес (кг)</mat-label>
                    <input
                      matInput
                      type="number"
                      [(ngModel)]="item.weight"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-3">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label>Высота (см)</mat-label>
                    <input
                      matInput
                      type="number"
                      [(ngModel)]="item.height"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-3">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label>Ширина (см)</mat-label>
                    <input
                      matInput
                      type="number"
                      [(ngModel)]="item.width"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-3">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label>Длина (см)</mat-label>
                    <input
                      matInput
                      type="number"
                      [(ngModel)]="item.length"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </mat-form-field>
                </div>
              </div>
              <div class="row justify-content-between align-items-center">
                <div
                  class="col-6"
                  matTooltip="Условие создания - Фактический и Объемный вес от 2 до 30 кг. Размеры посылки от 20 до 120 см. Одна строна посылки должна быть >= 40см"
                  matTooltipClass="tooltip"
                >
                  <div class="row align-items-center">
                    <div class="col-auto">
                      <mat-checkbox
                        value="1"
                        color="primary"
                        [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="item.specialCargo"
                        >Упаковка без коробки
                      </mat-checkbox>
                    </div>
                    <div class="col-auto">
                      <mat-icon>help</mat-icon>
                    </div>
                  </div>
                </div>

                <div class="col-5 text-center">
                  <button
                    mat-button
                    [matMenuTriggerFor]="menu"
                    class="btn btn-primary"
                  >
                    Пакет
                  </button>
                  <mat-menu #menu="matMenu">
                    <button
                      mat-menu-item
                      (click)="setParamData(item, index, 1)"
                    >
                      Пакет 0.5 кг
                    </button>
                    <button
                      mat-menu-item
                      (click)="setParamData(item, index, 2)"
                    >
                      Пакет 1 кг
                    </button>
                  </mat-menu>
                </div>

                <div class="col-1" *ngIf="index > 0">
                  <button
                    type="button"
                    mat-button
                    class="btn-round-sm btn-danger"
                    matTooltip="Удалить место"
                    matTooltipClass="tooltip"
                    (click)="deletePlace(index)"
                  >
                    <mat-icon>remove</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                <button
                  type="button"
                  mat-button
                  class="btn-round-sm btn-gray"
                  matTooltip="Добавить место"
                  matTooltipClass="tooltip"
                  (click)="addNewPlace()"
                >
                  <mat-icon>add</mat-icon>
                </button>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card class="card-box my-2">
          <mat-card-subtitle> Оплата</mat-card-subtitle>

          <mat-card-content>
            <div class="row">
              <div class="col-6">
                <mat-form-field appearance="outline" style="width: 100%">
                  <mat-label>Оценочная стоимость</mat-label>
                  <input
                    matInput
                    type="number"
                    [disabled]="disabled"
                    formControlName="assessedValue"
                  />
                  <button
                    mat-icon-button
                    matTooltip="Редактировать оценочную стоимость"
                    matTooltipClass="tooltip"
                    matSuffix
                    (click)="onChangeDisabled()"
                  >
                    <mat-icon>{{
                      disabled ? "toggle_on" : "toggle_off"
                    }}</mat-icon>
                  </button>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field appearance="outline" style="width: 100%">
                  <mat-label>Сума наложенного платежа</mat-label>
                  <input
                    matInput
                    type="number"
                    formControlName="cashOnDelivery"
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6">
                <mat-form-field appearance="outline" style="width: 100%">
                  <mat-label>Оплата доставки</mat-label>
                  <mat-select formControlName="payment">
                    <mat-option [value]="1">Получатель</mat-option>
                    <mat-option [value]="2">Отправитель</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-12 col-md-6">
                <mat-slide-toggle
                  formControlName="afterPaymentOnGoodsCost"
                  color="primary"
                >
                  Контроль оплаты
                </mat-slide-toggle>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <div class="row my-3">
          <div class="col text-center">
            <div [ngSwitch]="response.code">
              <div *ngSwitchCase="200" class="alert-success p-5 mb-4 h2">
                <div class="row align-items-center">
                  <div class="col">
                    {{ response.ttn }}
                  </div>
                  <div class="col">
                    <a
                      mat-button
                      class="btn btn-warning"
                      [href]="response.link"
                      target="_blank"
                      matTooltip="Печать ТТН"
                      matTooltipClass="tooltip"
                      (click)="onPrintTtn(response.ttn)"
                    >
                      <mat-icon>print</mat-icon>
                    </a>
                  </div>
                </div>
              </div>
              <div *ngSwitchCase="400" class="alert-danger p-5 mb-4">
                {{ response.ttn }}
              </div>
            </div>
            <div class="row justify-content-around align-items-center">
              <div class="col-auto">
                <button
                  mat-button
                  (click)="onSaveTtn()"
                  class="btn btn-primary"
                >
                  Сохранить
                </button>
              </div>
              <div class="col-auto">
                <button
                  mat-button
                  (click)="onCreateTtn()"
                  class="btn btn-success btn-lg"
                >
                  Создать ТТН
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<ng-template #loading>
  <div class="d-flex justify-content-center my-5">
    <mat-spinner diameter="25"></mat-spinner>
  </div>
</ng-template>
