<div class="container-fluid">
  <div class="row">
    <div class="col">
      <mat-card>
        <mat-card-title
          >Отчет ROMI - отчет актуален до 01.08.2021</mat-card-title
        >
        <mat-divider></mat-divider>
        <mat-card-content class="pt-3">
          <form [formGroup]="form" id="filterForm" (ngSubmit)="getRomiInfo()">
            <div class="row align-items-center">
              <div class="col-12 col-md-3">
                <mat-form-field>
                  <mat-label>Диапазон</mat-label>
                  <mat-date-range-input [rangePicker]="picker">
                    <input
                      matStartDate
                      formControlName="start"
                      placeholder="Дата начала"
                    />
                    <input
                      matEndDate
                      formControlName="end"
                      placeholder="Дата конца"
                    />
                  </mat-date-range-input>
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-date-range-picker
                    (closed)="getDynamicSpr()"
                    #picker
                  ></mat-date-range-picker>
                </mat-form-field>
              </div>
              <div class="col-auto">
                <mat-button-toggle-group
                  class="mat-btn-sm"
                  formControlName="dataType"
                >
                  <mat-button-toggle value="1">Час</mat-button-toggle>
                  <mat-button-toggle value="2">День</mat-button-toggle>
                  <mat-button-toggle value="3">Месяц</mat-button-toggle>
                  <mat-button-toggle value="4">Год</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="col-auto">
                <button
                  mat-button
                  type="submit"
                  class="btn btn-success"
                  form="filterForm"
                >
                  Отправить
                </button>
              </div>
            </div>
            <div class="row">
              <!--          <div class="col-12 col-md-3">
                          <div class="row align-items-center">
                            <div class="col-1 text-center">
                              <input type="radio" form="filterForm" formControlName="typeFilter" value="1">
                            </div>
                            <div class="col-auto">
                              <mat-form-field>
                                <mat-label>Источник</mat-label>
                                <mat-select formControlName="source" multiple>
                                  <mat-option #allSelectedSource (click)="toggleAllSource()" [value]="'all'">Выбрать все
                                  </mat-option>
                                  <mat-divider></mat-divider>
                                  <mat-option *ngFor="let spr of sourceList" [value]="spr.value">
                                    {{spr.text}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>
                        </div>-->
              <div class="col-12 col-md-3">
                <div class="row align-items-center">
                  <div class="col-1 text-center">
                    <input
                      type="radio"
                      form="filterForm"
                      formControlName="typeFilter"
                      value="3"
                    />
                  </div>
                  <div class="col-auto">
                    <mat-form-field>
                      <mat-label>Автомобиль</mat-label>
                      <mat-select formControlName="car" multiple>
                        <mat-option
                          #allSelectedCar
                          (click)="toggleAllCar()"
                          [value]="'all'"
                          >Выбрать все
                        </mat-option>
                        <mat-divider></mat-divider>
                        <mat-option
                          *ngFor="let spr of carList"
                          [value]="spr.value"
                        >
                          {{ spr.text }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <!--
                            <div class="col-12 col-md-3">
                              <div class="row align-items-center">
                                <div class="col-1 text-center">
                                  <input type="radio" form="filterForm" formControlName="typeFilter" value="5">
                                </div>
                                <div class="col-auto">
                                  <mat-form-field>
                                    <mat-label>Товарная группа</mat-label>
                                    <mat-select formControlName="prodGroup" multiple>
                                      <mat-option #allSelectedGroup (click)="toggleAllGroup()" [value]="'all'">Выбрать все
                                      </mat-option>
                                      <mat-divider></mat-divider>
                                      <mat-option *ngFor="let spr of prodGroupList" [value]="spr.value">
                                        {{spr.text}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </div>
                              </div>

                            </div>-->
            </div>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="row my-3">
    <div class="col">
      <p-table
        [columns]="cols"
        [value]="orderList"
        [loading]="loading"
        [rowsPerPageOptions]="[10, 25, 50, 100]"
        [paginator]="true"
        currentPageReportTemplate="Элементов на странице ({first} - {last}) з {totalRecords} "
        [filterDelay]="0"
        autoLayout="true"
        [rows]="10"
        selectionMode="single"
        styleClass="p-datatable-gridlines p-datatable-sm"
      >
        <ng-template pTemplate="caption">
          <button class="btn btn-primary" mat-button>
            Умная КМС - {{ getCMS }}
          </button>
          <button class="btn btn-primary ml-2" mat-button>
            Ремаркетинг - {{ getRemarket }}
          </button>
        </ng-template>

        <ng-template pTemplate="header" let-columns>
          <tr>
            <th
              *ngFor="let col of columns"
              [pSortableColumn]="col.field"
              style="font-weight: 400; font-size: 12px"
            >
              <p-sortIcon [field]="col.field"></p-sortIcon>
              {{ col.header }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td>{{ rowData.filter }}</td>
            <td>{{ rowData.grossSale }}</td>
            <td>
              {{ rowData.investments }}(
              <span
                [matTooltip]="'ANALYTIC.ROMI.TOOLTIP_CONTEXT' | translate"
                matTooltipClass="tooltip"
                >{{ rowData.context }}</span
              >
              &nbsp;/&nbsp;
              <span
                [matTooltip]="'ANALYTIC.ROMI.TOOLTIP_FEED' | translate"
                matTooltipClass="tooltip"
                >{{ rowData.feed }}</span
              >
              )
            </td>
            <td>{{ rowData.cost }}</td>
            <td
              [ngClass]="{
                'bg-danger': rowData.romi < 0,
                'bg-success': rowData.romi >= 50
              }"
            >
              {{ rowData.romi }}
            </td>
            <td>{{ rowData.presentationCost }}</td>
            <td>{{ rowData.presentation }}</td>
            <td>{{ rowData.orderCost }}</td>
            <td>{{ rowData.order }}</td>
            <td>{{ rowData.conv }}</td>
            <td class="text-center">
              <button
                type="button"
                mat-icon-button
                matTooltip="Показать в разрезе марки"
                matTooltipClass="tooltip"
                (click)="showMarkInfo(rowData.filterId)"
              >
                <mat-icon style="font-size: 18px">more_vert</mat-icon>
              </button>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="footer">
          <tr [class]="footerInfo.class">
            <td *ngIf="footerInfo.colspan" [colSpan]="footerInfo.colspan">
              Итого
            </td>
            <td>{{ footerInfo.grossSale }}</td>
            <td>
              {{ footerInfo.investments }}
              ({{ footerInfo.sumContext }}/{{ footerInfo.sumFeed }})
            </td>
            <td>
              {{
                srv.roundNumber(footerInfo.grossSale - footerInfo.investments)
              }}
            </td>
            <td>{{ footerInfo.romi }}</td>
            <td>
              {{
                srv.roundNumber(
                  footerInfo.investments / footerInfo.presentation
                )
              }}
            </td>
            <td>{{ footerInfo.presentation }}</td>
            <td>
              {{ srv.roundNumber(footerInfo.investments / footerInfo.order) }}
            </td>

            <td>{{ footerInfo.order }}</td>
            <td>{{ footerInfo.conv }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <div class="row my-3">
    <div class="col-12 col-md-6">
      <mat-card class="my-2">
        <mat-label>Доход в разрезе марок</mat-label>
        <p-chart type="pie" [data]="pieData"></p-chart>
      </mat-card>
    </div>
    <div class="col-12 col-md-6" *ngFor="let item of lineDataArr">
      <mat-card class="my-2">
        <mat-label>{{ item.labelChart }}</mat-label>
        <p-chart type="line" [data]="item"></p-chart>
      </mat-card>
    </div>
  </div>
</div>
