<mat-card-title>
  <div class="row justify-content-between mb-3">
    <div class="col"></div>
    <div class="col-auto">
      <button
        type="button"
        mat-icon-button
        class="btn btn-primary btn-sm"
        matTooltip="Закрыть"
        matTooltipPosition="left"
        matTooltipClass="tooltip"
        matDialogClose=""
      >
        <img src="assets/img/icon/close/Icon_Close.svg" alt="close" />
      </button>
    </div>
  </div>
</mat-card-title>

<form [formGroup]="form" (ngSubmit)="onCreateNewRequest()">
  <mat-card-content *ngIf="prodInfo$ | async as prodInfo">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th class="pb-5">Запрос</th>
          <th colspan="2">
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>Тип запроса</mat-label>
              <mat-select
                formControlName="reqCat"
                (selectionChange)="setComment()"
              >
                <mat-option [value]="1">Уточнить наличие</mat-option>
                <mat-option [value]="2">Уточнить характеристики</mat-option>
              </mat-select>
            </mat-form-field>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td colspan="3"></td>
        </tr>
        <tr>
          <td>Товар</td>
          <th colspan="2">{{ prodInfo.katalNumber }} {{ prodInfo.brand }}</th>
        </tr>
        <tr>
          <td>Кол-во</td>
          <th>{{ prodInfo.count }} шт</th>
          <td></td>
        </tr>
        <tr>
          <td>Склад</td>
          <th>{{ prodInfo.codeProvider }}</th>
          <td></td>
        </tr>
        <tr>
          <td>Срок</td>
          <th>{{ prodInfo.planDateSend }} {{ prodInfo.planDateSendTime }}</th>
          <td></td>
        </tr>

        <tr>
          <td>Коментарий</td>
          <td colspan="2">
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>Введите комментарий</mat-label>

              <textarea matInput formControlName="comment" rows="8"></textarea>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>Автор</td>
          <th colspan="2">{{ prodInfo.manager }}</th>
        </tr>
      </tbody>
    </table>
  </mat-card-content>
  <mat-card-actions class="text-center">
    <button mat-button class="btn btn-success">Отправить</button>
  </mat-card-actions>
</form>
