<mat-card-title>
  <div class="row justify-content-between mb-3">
    <div class="col-auto h4"></div>
    <div class="col-auto">
      <button
        type="button"
        mat-icon-button
        class="btn btn-primary btn-sm"
        matTooltip="Закрыть"
        matTooltipPosition="left"
        matTooltipClass="tooltip"
        matDialogClose=""
      >
        <img src="assets/img/icon/close/Icon_Close.svg" alt="close" />
      </button>
    </div>
  </div>
</mat-card-title>
<form [formGroup]="form" (ngSubmit)="onCreateResponseRequest()">
  <mat-card-content *ngIf="prodInfo$ | async as prodInfo">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th class="py-0" style="vertical-align: middle">Запрос</th>
          <th class="py-0" style="vertical-align: middle" colspan="2">
            <mat-form-field
              appearance="outline"
              style="width: 100%; margin-bottom: -1.25em"
            >
              <mat-label>Тип запроса</mat-label>
              <mat-select formControlName="reqCat" disabled>
                <mat-option [value]="1">Уточнить наличие</mat-option>
                <mat-option [value]="2">Уточнить характеристики</mat-option>
              </mat-select>
            </mat-form-field>

            <span [ngSwitch]="+this.prodInfo.delivery">
              <mat-icon
                *ngSwitchCase="6"
                matTooltip="Курьером"
                matTooltipClass="tooltip"
                >local_taxi</mat-icon
              >
              <mat-icon
                *ngSwitchCase="7"
                matTooltip="Самовывоз"
                matTooltipClass="tooltip"
                >directions_run</mat-icon
              >
              <mat-icon
                *ngSwitchDefault
                matTooltip="Перевозчик"
                matTooltipClass="tooltip"
                >local_shipping</mat-icon
              >
            </span>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td colspan="3"></td>
        </tr>
        <tr>
          <td class="py-0" style="vertical-align: middle">Автор</td>
          <th class="py-0" style="vertical-align: middle" colspan="2">
            {{ prodInfo.manager }}
          </th>
        </tr>
        <tr>
          <td class="py-0" style="vertical-align: middle">Исполнитель</td>
          <th class="py-0" style="vertical-align: middle" colspan="2">
            Ответить до {{ dialogData.dateEnd | date: "dd-MM-YYYY HH:mm" }}
          </th>
        </tr>
        <tr>
          <td
            class="py-0"
            style="vertical-align: middle; border: 3px solid #000 !important"
          >
            Товар
          </td>
          <th
            class="py-0"
            style="vertical-align: middle; border: 3px solid #000 !important"
            colspan="2"
          >
            {{ prodInfo.katalNumber }} {{ prodInfo.brand }}
          </th>
        </tr>
        <tr>
          <td
            class="py-0"
            style="vertical-align: middle; border: 3px solid #000 !important"
          >
            Кол-во
          </td>
          <th
            class="py-0"
            style="vertical-align: middle; border: 3px solid #000 !important"
            [ngClass]="{
              'bg-danger': !(+this.dialogData.count === +this.form.value.count)
            }"
          >
            {{ this.dialogData.count }} шт
          </th>
          <td
            class="py-0"
            style="vertical-align: middle; border: 3px solid #000 !important"
          >
            <mat-form-field
              appearance="outline"
              style="width: 100%; margin-bottom: -1.25em"
            >
              <mat-label>Кол-во</mat-label>
              <mat-select
                formControlName="count"
                (selectionChange)="changeResponse()"
                [disabled]="+dialogData.category == 2"
              >
                <mat-option *ngFor="let item of countList" [value]="item">{{
                  item
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td
            class="py-0"
            style="vertical-align: middle; border: 3px solid #000 !important"
          >
            Склад
          </td>
          <th
            class="py-0"
            style="vertical-align: middle; border: 3px solid #000 !important"
            [ngClass]="{
              'bg-danger': !(
                +this.dialogData.warehouse === +this.form.value.warehouse
              )
            }"
          >
            {{ this.dialogData.warehouse }}
          </th>
          <td
            class="py-0"
            style="vertical-align: middle; border: 3px solid #000 !important"
          >
            <mat-form-field
              appearance="outline"
              style="width: 100%; margin-bottom: -1.25em"
              *ngIf="warehouseList$ | async as warehouseList"
            >
              <mat-label>Склад</mat-label>
              <mat-select
                formControlName="warehouse"
                (selectionChange)="changeResponse()"
                [disabled]="+dialogData.category == 2"
              >
                <mat-option
                  *ngFor="let item of warehouseList"
                  [value]="item.value"
                >
                  {{ item.value }} - {{ item.info }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td
            class="py-0"
            style="vertical-align: middle; border: 3px solid #000 !important"
          >
            Срок
          </td>
          <td
            class="py-0"
            style="vertical-align: middle; border: 3px solid #000 !important"
            [ngClass]="{
              'text-center': true,
              'bg-danger': !(
                this.dialogData.date ===
                this.srv.convertDateTime(this.form.get('data').value)
              )
            }"
          >
            <b>{{ this.dialogData.date | date: "dd-MM-YYYY HH:mm" }}</b>
          </td>
          <td
            class="py-0"
            style="vertical-align: middle; border: 3px solid #000 !important"
          >
            <mat-form-field
              appearance="outline"
              style="width: 100%; margin-bottom: -1.25em"
            >
              <mat-label>Сроки</mat-label>
              <input
                matInput
                formControlName="data"
                type="datetime-local"
                (change)="changeResponse()"
                [readonly]="+dialogData.category == 2"
              />
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td class="py-0" style="vertical-align: middle">Коментарий</td>
          <th
            class="py-0"
            style="vertical-align: middle"
            [ngClass]="{
              'bg-danger': !(
                this.dialogData.comment === this.form.value.comment
              )
            }"
          >
            {{ dialogData.comment }}
          </th>
          <td class="py-0" style="vertical-align: middle">
            <mat-form-field
              appearance="outline"
              style="width: 100%; margin-bottom: -1.25em"
            >
              <mat-label>Введите комментарий</mat-label>
              <textarea
                matInput
                formControlName="comment"
                rows="3"
                (keyup)="changeResponse()"
              ></textarea>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td class="py-0" style="vertical-align: middle">Результат</td>
          <td class="py-0" style="vertical-align: middle" colspan="2">
            <button
              mat-button
              class="btn btn-block"
              [ngClass]="{
                'btn-danger': !styleResponse,
                'btn-success': styleResponse
              }"
            >
              {{ styleResponse ? "Подтвержден" : "Изменен" }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </mat-card-content>
</form>
