import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderShieldInfoComponent } from './header-shield-info.component';
import { MatCardModule } from '@angular/material/card';
import { TitleModalWindowModule } from '../../shared/title-modal-window/title-modal-window.module';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [HeaderShieldInfoComponent],
  imports: [
    CommonModule,
    MatCardModule,
    TitleModalWindowModule,
    MatButtonModule,
  ],
})
export class HeaderShieldInfoModule {}
