<div class="container-fluid my-3">
  <div class="row">
    <div class="col">
      <mat-card>
        <mat-card-title>Журнал звонков</mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content class="pt-3">
          <form
            [formGroup]="form"
            id="filterForm"
            (ngSubmit)="getCallListInfo()"
          >
            <div class="row align-items-center">
              <div class="col-12 col-md-3">
                <mat-form-field>
                  <mat-label>Диапазон</mat-label>
                  <mat-date-range-input [rangePicker]="picker">
                    <input
                      matStartDate
                      formControlName="start"
                      placeholder="Дата начала"
                    />
                    <input
                      matEndDate
                      formControlName="end"
                      placeholder="Дата конца"
                    />
                  </mat-date-range-input>
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </div>
              <div class="col-auto">
                <button
                  mat-button
                  type="submit"
                  class="btn btn-success"
                  form="filterForm"
                >
                  Отправить
                </button>
              </div>
            </div>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="row my-3">
    <div class="col">
      <p-table
        *ngIf="vm$ | async as clientList"
        [columns]="clientList.headerList"
        [value]="clientList.callList"
        [rowsPerPageOptions]="[10, 25, 50, 100, 500]"
        [paginator]="true"
        currentPageReportTemplate="Элементов на странице ({first} - {last}) з {totalRecords} "
        [filterDelay]="0"
        autoLayout="true"
        [rows]="50"
        selectionMode="single"
        styleClass="p-datatable-gridlines p-datatable-sm"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th
              *ngFor="let col of columns"
              [pSortableColumn]="col.field"
              style="font-weight: 400; font-size: 12px"
            >
              <p-sortIcon [field]="col.field"></p-sortIcon>
              {{ col.header }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td
              pEditableColumn
              *ngFor="let col of columns; let index = index"
              style="font-weight: 300; font-size: 12px"
              [ngClass]="{
                'true-row':
                  (+rowData['callType'] == 1 &&
                    rowData['actionName'] == 'Отработано') ||
                  (+rowData['callType'] == 2 && +rowData['action'] == 2),
                'wait-row':
                  +rowData['callType'] == 1 &&
                  (rowData['actionName'] == 'Нет ответа' ||
                    rowData['actionName'] == 'В процессе'),
                'danger-row':
                  +rowData['callType'] == 2 && +rowData['action'] == 1
              }"
            >
              <span [ngSwitch]="col.field">
                <span *ngSwitchCase="'clientName'">
                  <a [routerLink]="['/crm/user-card/' + rowData['clientId']]">
                    {{ rowData["clientName"] }}
                  </a>
                </span>

                <span *ngSwitchCase="'phone'">
                  {{ srv.hidePhone(rowData["phone"]) }}
                </span>
                <span *ngSwitchCase="'actionName'">
                  <div *ngIf="rowData['callType'] == 2; else showName">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <p-dropdown
                          [options]="statuses"
                          (onChange)="changeStatus($event.value, rowData)"
                          [(ngModel)]="rowData['action']"
                          [style]="{ width: '100%' }"
                        ></p-dropdown>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{ rowData["actionName"] }}
                      </ng-template>
                    </p-cellEditor>
                  </div>

                  <ng-template #showName>
                    {{ rowData[col.field] }}
                  </ng-template>
                </span>
                <span *ngSwitchCase="'actionBtn'">
                  <div
                    *ngIf="
                      rowData['callType'] == 2 &&
                      showCreateOrder &&
                      showCreateOrder.length > 0
                    "
                  >
                    <button
                      mat-button
                      class="btn btn-primary"
                      (click)="onCreateNewOrderToUser(rowData['clientId'])"
                    >
                      Создать заказ
                    </button>
                  </div>
                </span>
                <span *ngSwitchDefault>{{ rowData[col.field] }}</span>
              </span>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div *ngIf="loading">
        <div class="d-flex justify-content-center my-5">
          <mat-spinner diameter="25"></mat-spinner>
        </div>
      </div>
    </div>
  </div>
</div>
