<div class="row">
  <div class="col-12 col-md-2 order-1 order-md-0 px-0">
    <div class="img-thumb" style="max-height: 300px; overflow-y: auto">
      <img
        *ngFor="let i of img; let select = index"
        [ngClass]="select == imgSlide ? 'img-active' : ''"
        src="{{ i.src }}"
        alt="{{ i.alt }}"
        (mouseenter)="onSetCarouselItem(select)"
      />
    </div>
  </div>
  <div class="col-12 col-md-10 order-0 order-md-1 px-md-0 align-items-center">
    <div class="img-container">
      <img
        *ngFor="let i of img; let select = index"
        [ngClass]="select == imgSlide ? 'active' : ''"
        src="{{ i.src }}"
        alt="{{ i.alt }}"
        style="max-height: 300px"
      />
    </div>
  </div>
</div>
