<div class="row justify-content-center align-items-center" style="height: 100%">
  <div class=" col-auto">
    <mat-card class="alert alert-danger my-3" *ngIf="auth.error$ | async as error">
      <mat-card-content>{{error}}</mat-card-content>
    </mat-card>
    <mat-card class="card-box">
      <mat-card-subtitle>Авторизация</mat-card-subtitle>
      <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="login()" id="loginForm">
          <div class="row">
            <div class="col-12 col-md-6">
              <mat-form-field>
                <mat-label>Телефон</mat-label>
                <input
                  type="text"
                  prefix="+38"
                  [mask]="' (000) 000-00-99'"
                  matInput
                  formControlName="phone"
                >

                <mat-error *ngIf="form.get('phone').hasError('required')">
                  Телефон <strong>Обязательное</strong>
                </mat-error>
                <mat-error *ngIf="form.get('phone').hasError('minlength')">
                  Номер телефона некорректный
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <mat-form-field>
                <mat-label>Пароль</mat-label>
                <input
                  matInput
                  formControlName="password"
                  [type]="hide ? 'password' : 'text'"
                >
                <button mat-icon-button
                        matSuffix
                        (click)="hide = !hide"
                        [attr.aria-label]="'Спрятать'"
                        [attr.aria-pressed]="hide">
                  <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="form.get('password').hasError('required')">
                  Пароль <strong>обязательный</strong>
                </mat-error>
                <mat-error *ngIf="form.get('password').hasError('minlength')">
                  Пароль некорректный
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>
      </mat-card-content>
      <mat-card-actions align="end">
        <button mat-button
                type="submit"
                form="loginForm"
                [disabled]="form.invalid || submitted"
                class="btn btn-sm btn-success">Войти</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
