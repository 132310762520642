<mat-card-title>
  <div class="row justify-content-between align-items-center mb-3">
    <div class="col-10">Информация по складам</div>
    <div class="col-2">
      <button
        type="button"
        mat-icon-button
        class="btn btn-primary btn-sm"
        matTooltip="Закрыть"
        matTooltipPosition="left"
        matTooltipClass="tooltip"
        matDialogClose=""
      >
        <img src="assets/img/icon/close/Icon_Close.svg" alt="close" />
      </button>
    </div>
  </div>
</mat-card-title>
<mat-card-content>
  <div [innerHtml]="dataObj.text"></div>
</mat-card-content>
