<mat-card-title>
  <div class="row justify-content-between mb-3">
    <div class="col-auto h2">
      <b>{{ title }}</b>
    </div>
    <div class="col-auto">
      <button
        type="button"
        mat-icon-button
        class="btn btn-primary btn-sm"
        [matTooltip]="'Зарыть'"
        matTooltipPosition="left"
        matTooltipClass="tooltip"
        matDialogClose=""
      >
        <img src="assets/img/icon/close/Icon_Close.svg" alt="close" />
      </button>
    </div>
  </div>
</mat-card-title>
