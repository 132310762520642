<p-table
  [columns]="resultData.cols"
  [value]="resultData.rows"
  [rowsPerPageOptions]="[10, 25, 50, 100]"
  [paginator]="true"
  currentPageReportTemplate="Элементов на странице ({first} - {last}) з {totalRecords} "
  [filterDelay]="0"
  autoLayout="true"
  [rows]="20"
  selectionMode="single"
  styleClass="p-datatable-gridlines p-datatable-sm"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th
        *ngFor="let col of columns"
        [pSortableColumn]="col.field"
        style="font-weight: 400; font-size: 12px"
      >
        <p-sortIcon [field]="col.field"></p-sortIcon>
        {{ col.header }}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td
        [ngClass]="{ 'bg-danger': rowData.missed, 'bg-warning-dark':+rowData.priority==1 && rowData.missed, 'bg-warning-light':+rowData.priority==1 && !rowData.missed }"
        *ngFor="let col of columns"
        style="font-weight: 300; font-size: 12px"
      >
        <div [ngSwitch]="col.field">
          <div *ngSwitchCase="'actionId'">
            <div [ngSwitch]="+rowData.actionId">
              <button
                *ngSwitchCase="2"
                mat-button
                class="btn btn-success btn-small"
                [matTooltip]="rowData.action"
                matTooltipClass="tooltip"
              >
                <mat-icon style="font-size: 12px">check</mat-icon>
              </button>

              <button
                *ngSwitchCase="1"
                mat-button
                class="btn btn-warning btn-small"
                [matTooltip]="rowData.action"
                matTooltipClass="tooltip"
              >
                <mat-icon style="font-size: 12px">hourglass_top</mat-icon>
              </button>
            </div>
          </div>
          <div *ngSwitchCase="'actionRandom'">
            <button type="button" (click)="showCard(rowData)" mat-icon-button>
              <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
          <div *ngSwitchCase="'typeId'">
            <div [ngSwitch]="+rowData.typeId">
              <button
                *ngSwitchCase="2"
                mat-button
                class="btn btn-success btn-small"
                matTooltip="Логист"
                matTooltipClass="tooltip"
              >
                <mat-icon style="font-size: 12px"
                  >store_mall_directory
                </mat-icon>
              </button>

              <button
                *ngSwitchCase="1"
                mat-button
                class="btn btn-warning btn-small"
                matTooltip="Менеджер"
                matTooltipClass="tooltip"
              >
                <mat-icon style="font-size: 12px">person_pin</mat-icon>
              </button>
            </div>
          </div>
          <div *ngSwitchCase="'actionTask'">
            <a
              *ngIf="+rowData.cat == 2 || +rowData.cat == 11"
              mat-icon-button
              (click)="setTaskToWork(rowData.id, rowData.cat)"
              [routerLink]="'/crm/balance-client/' + rowData.userIdHesh"
              [queryParams]="{
                task: rowData.id
              }"
              target="_blank"
              class="text-primary"
            >
              <mat-icon>chevron_right</mat-icon>
            </a>
            <a
              *ngIf="+rowData.cat == 16"
              mat-icon-button
              (click)="setTaskToWork(rowData.id, rowData.cat)"
              [routerLink]="'/crm/return-product-confirm'"
              [queryParams]="{
                task: rowData.id
              }"
              target="_blank"
              class="text-primary"
            >
              <mat-icon>chevron_right</mat-icon>
            </a>
            <a
              *ngIf="
                +rowData.cat !== 2 && +rowData.cat !== 11 && +rowData.cat !== 16
              "
              mat-icon-button
              (click)="setTaskToWork(rowData.id, rowData.cat)"
              [routerLink]="'/crm/order-card/' + rowData.orderNumber"
              [queryParams]="{
                task: rowData.id
              }"
              target="_blank"
              class="text-primary"
            >
              <mat-icon>chevron_right</mat-icon>
            </a>
          </div>
          <div *ngSwitchCase="'actionTaskFrom'">
            <button
              *ngIf="+rowData.actionId == 3"
              type="button"
              mat-icon-button
              class="btn-success btn"
              matTooltip="Отправить в архив"
              matTooltipClass="tooltip"
              (click)="setTaskToArchive(rowData)"
            >
              <mat-icon>check</mat-icon>
            </button>
            <a
              mat-icon-button
              [routerLink]="'/crm/order-card/' + rowData.orderNumber"
              [queryParams]="{
                task: rowData.id
              }"
              target="_blank"
              class="text-primary"
            >
              <mat-icon>chevron_right</mat-icon>
            </a>
          </div>
          <div *ngSwitchCase="'actionPres'">
            <a
              mat-icon-button
              [routerLink]="'/crm/pretension/' + rowData.id"
              [queryParams]="{
                task: rowData.id
              }"
              target="_blank"
              class="text-primary"
            >
              <mat-icon>chevron_right</mat-icon>
            </a>
          </div>
          <div *ngSwitchCase="'actionReq'">
            <a
              [href]="
                '/crm/order-card/' +
                rowData.zakaz +
                '?requestProd=' +
                rowData.prodId
              "
              mat-button
              class="btn btn-round-sm"
            >
              <mat-icon>chevron_right</mat-icon>
            </a>
          </div>
          <div *ngSwitchDefault>
            {{ rowData[col.field] }}
          </div>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
