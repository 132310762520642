import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'lib-shared-provider-count-table',
  templateUrl: 'shared-provider-count-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedProviderCountTableComponent {
  @Input() providerInfo;
  @Input() header = true;
}
