<mat-card-title>
  <div class="row justify-content-between mb-3">
    <div class="col-auto h2"><b>Добавить банковские реквизиты</b></div>
    <div class="col-auto">
      <button type="button" mat-icon-button class="btn btn-primary btn-sm"
              matTooltip="Закрыть"
              matTooltipPosition="left"
              matTooltipClass="tooltip"
              matDialogClose>
        <img src="assets/img/icon/close/Icon_Close.svg" alt="close">
      </button>
    </div>
  </div>
</mat-card-title>

<mat-card-content>
  <form [formGroup]="form" (ngSubmit)="addNewPayment()">
    <div class="row">
      <div class="col">
        <mat-radio-group formControlName="typePayment">
          <mat-radio-button class="mr-4" value="1">Банковская карта</mat-radio-button>
          <mat-radio-button value="2" class="mr-4">Расчетный счет</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="row my-3">
      <div class="col">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>Расчетный счет<span class="text-danger">*</span></mat-label>
          <input matInput
                 type="text"
                 [mask]="(+form.get('typePayment').value===1)?'AAAA-AAAA-AAAA-AAAA':''"
                 formControlName="valuePayment"
          />
          <mat-error *ngIf="form.get('valuePayment').hasError('required')">
            Введите платежные реквизиты
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row my-3">
      <div class="col">
        <mat-checkbox color="primary" formControlName="defaultPayment">Установить по умолчанию</mat-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <button mat-button class="btn btn-success px-5">Добавить</button>
      </div>
    </div>
  </form>
</mat-card-content>

