import {Component, Inject, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {RsService} from '../core/rs.service';

@Component({
  selector: 'app-romi-dialog-model',
  templateUrl: './romi-dialog-model.component.html',
  styleUrls: ['./romi-dialog-model.component.scss']
})
export class RomiDialogModelComponent implements OnInit {

  infoList$: Observable<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private rs: RsService,
    private dialog: MatDialog
  ) {
  }



  ngOnInit(): void {
    this.loadInfoModel();
  }

  loadInfoModel(): void {
    this.infoList$ = this.rs.loadInfoModel(
      this.dialogData.model,
      this.dialogData.mark,
      this.dialogData.dateStart,
      this.dialogData.dateEnd);
  }

}
