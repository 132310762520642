import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-s-dialog-show-settings-info',
  templateUrl: './s-dialog-show-settings-info.component.html',
  styleUrls: ['./s-dialog-show-settings-info.component.scss']
})
export class SDialogShowSettingsInfoComponent implements OnInit {
  displayedColumns: string[] = ['name', 'value'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,

  ) { }

  ngOnInit(): void {
  }

}
