import {AfterContentChecked, AfterViewChecked, AfterViewInit, Component, DoCheck, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-img-gallery',
  templateUrl: './img-gallery.component.html',
  styleUrls: ['./img-gallery.component.scss']
})
export class ImgGalleryComponent implements OnInit, OnChanges {

  @Input() img; // массив с изображениями
  @Input() video; // массив с изображениями
  imgSlide: number;

  imgSrc: string;
  imgSrcQv: string;

  constructor(
    private dialog: MatDialog
  ) {
    this.imgSrc = 'assets/img/icon/video/video_img.svg';
    this.imgSrcQv = 'assets/img/icon/product/1 (1).svg';
    this.imgSlide = 0;
  }

  ngOnInit() {


  }

  ngOnChanges() {
    this.imgSlide = 0;
  }


  onSetCarouselItem(select: number) {
    this.imgSlide = select;
  }


}
