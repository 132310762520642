<div
  class="container-fluid"
  *ngIf="userCardData$ | async as userCardData; else loading"
>
  <div class="row mt-2">
    <div class="col-12 col-md-3">
      <mat-card class="card-box">
        <div class="row">
          <div class="col-10">
            <mat-card-title
              ><b
                >{{ userCardData.lastName }} {{ userCardData.name }} <br />{{
                  userCardData.middleName
                }}</b
              >
            </mat-card-title>
            <mat-card-subtitle *ngIf="userCardData.regDate;else createAccount"
              >{{ userCardData.regLastName }} {{ userCardData.regName }}
              <br />{{ userCardData.regDate | date: "dd-MM-YYYY HH:mm:ss" }}
              <br />{{ userCardData.regEmail }}
            </mat-card-subtitle>
            <ng-template #createAccount>
              <button (click)="createPersonalCabinet()" mat-button class="btn btn-primary">Создать кабинет</button>
            </ng-template>
          </div>
          <div class="col-2">
            <button
              type="button"
              mat-button
              class="btn btn-round btn-primary"
              (click)="editUserInfo()"
              style="padding-bottom: 0.15rem !important"
              matTooltip="Изменить данные"
              matTooltipClass="tooltip"
            >
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-divider></mat-divider>
            <div class="row my-3" *ngFor="let item of userCardData.phoneList">
              <div class="col-2 text-center text-gray">
                <mat-icon mat-list-icon>phone</mat-icon>
              </div>
              <div
                class="col-8 text-left copy-content"
                style="position: relative"
              >
                {{ srv.hidePhone(item.text) }}
                <button
                  [cdkCopyToClipboard]="item.text"
                  (click)="trueCopy()"
                  class="copy-content__btn mat-icon-button"
                  mat-icon-button
                  matTooltip="Скопировать телефонный номер"
                  matTooltipClass="tooltip"
                  matTooltipPosition="below"
                >
                  <span class="mat-button-wrapper"
                    ><mat-icon
                      class="material-icons_grey copy-content__icon mat-icon notranslate material-icons mat-icon-no-color"
                      role="img"
                      aria-hidden="true"
                      >content_copy</mat-icon
                    ></span
                  >
                </button>
              </div>
              <div class="col-2 text-center text-gray">
                <mat-icon
                  *ngIf="item.viber"
                  matTooltip="Есть вайбер"
                  matTooltipClass="tooltip"
                  >add_to_home_screen
                </mat-icon>
              </div>
            </div>
            <div class="row my-3" *ngFor="let item of userCardData.emailList">
              <div class="col-2 text-center text-gray">
                <mat-icon mat-list-icon>email</mat-icon>
              </div>
              <div
                class="col-8 text-left copy-content"
                style="position: relative"
              >
                {{ item.text }}
                <button
                  [cdkCopyToClipboard]="item.text"
                  (click)="trueCopy()"
                  class="copy-content__btn mat-icon-button"
                  mat-icon-button
                  matTooltip="Скопировать Email"
                  matTooltipClass="tooltip"
                  matTooltipPosition="below"
                >
                  <span class="mat-button-wrapper"
                    ><mat-icon
                      class="material-icons_grey copy-content__icon mat-icon notranslate material-icons mat-icon-no-color"
                      role="img"
                      aria-hidden="true"
                      >content_copy</mat-icon
                    ></span
                  >
                </button>
              </div>
            </div>
            <div class="row my-3">
              <div class="col">
                <mat-chip-list>
                  <mat-chip [class]="userCardData.saleRat">{{
                    userCardData.rating
                  }}</mat-chip>
                  <mat-chip *ngIf="userCardData.typeId">{{
                    userCardData.type
                  }}</mat-chip>
                  <mat-chip *ngIf="userCardData.badTypeId" class="btn-danger">{{
                    userCardData.badType
                  }}</mat-chip>
                  <mat-chip *ngIf="userCardData.cashBackRateName" class="btn-primary">{{
                    userCardData.cashBackRateName
                    }}</mat-chip>
                  <mat-chip>
                    <mat-icon>person</mat-icon>
                    {{
                      userCardData.orderUserStateCard == 0
                        ? "Новый"
                        : "Повторный"
                    }}</mat-chip
                  >
                </mat-chip-list>
              </div>
            </div>
            <mat-divider></mat-divider>
            <div class="row my-4">
              <div class="col">
                Баланс клиента:
                <a
                  [routerLink]="['/crm', 'balance-client', hash]"
                  mat-chip
                  [class]="[
                    userCardData.balance >= 0 ? 'btn-success' : 'btn-danger'
                  ]"
                  style="font-size: 16px !important; text-decoration: none"
                  >{{ userCardData.balance }}</a
                >
              </div>
              <div class="col">
                Кешбек клиента:
                <a
                  [routerLink]="['/crm', 'balance-client', hash]"
                  mat-chip
                  style="font-size: 16px !important; text-decoration: none"
                >{{ userCardData.cashBack }}</a
                >
              </div>
<!--              <div class="col">
                <a
                  target="_blank"
                  [href]="
                    'https://srv.avto100.in.ua/crm-avto100/bookkeeping-user-card?var=' +
                    hash
                  "
                  mat-chip
                  style="font-size: 16px !important; text-decoration: none"
                >
                  Старый баланс:</a
                >
              </div>-->
            </div>
            <div class="row my-4">
              <div class="col"  *ngIf="userCardData.action">
                Статус:
                <mat-chip>{{
                  userCardData.action
                  }}</mat-chip>
              </div>
              <div class="col"  *ngIf="userCardData.manager">
                Менеджер:
                <mat-chip>{{
                  userCardData.manager
                  }}</mat-chip>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="col-12 col-md-4">
      <mat-card
        class="card-box"
        *ngIf="userRecipientList$ | async as userRecipientList"
        style="min-height: 16.25rem"
      >
        <mat-card-title>
          <div class="row justify-content-between">
            <div class="col-auto"><b>Получатели</b></div>
            <div class="col-auto">
              <button
                type="button"
                mat-button
                class="btn btn-round btn-success"
                (click)="onAddNewRecipient()"
                matTooltip="Добавить получателя"
                matTooltipClass="tooltip"
                style="padding-bottom: 0.15rem !important"
              >
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>
        </mat-card-title>
        <p-table
          [value]="userRecipientList"
          styleClass=" p-datatable-sm p-datatable-gridlines p-datatable-striped"
          [scrollable]="true"
          scrollHeight="150px"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>ФИО</th>
              <th>Телефон</th>
              <th style="width: 7rem"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product>
            <tr>
              <td class="py-1" style="font-size: 14px">
                {{ product.nameShort }}
              </td>
              <td class="py-1" style="font-size: 14px">
                {{ srv.hidePhone(product.phone) }}
              </td>
              <td class="py-1 text-center" style="width: 7rem">
                <button
                  type="button"
                  mat-icon-button
                  class="text-gray"
                  (click)="
                    onEditRecipient(
                      product.id,
                      product.name,
                      product.lastName,
                      product.middleName,
                      product.phone
                    )
                  "
                  matTooltip="Редактировать получателя"
                  matTooltipClass="tooltip"
                >
                  <mat-icon style="font-size: 14px">edit</mat-icon>
                </button>
                <button
                  type="button"
                  mat-icon-button
                  class="text-gray"
                  (click)="onDeleteRecipient(product.id)"
                  matTooltip="Удалить получателя"
                  matTooltipClass="tooltip"
                >
                  <mat-icon style="font-size: 14px">delete</mat-icon>
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </mat-card>
      <mat-card
        class="card-box mt-2"
        *ngIf="userPaymentList$ | async as userPaymentList"
        style="min-height: 16.25rem"
      >
        <mat-card-title>
          <div class="row justify-content-between">
            <div class="col-auto"><b>Банковские карты</b></div>
            <div class="col-auto">
              <button
                type="button"
                mat-button
                class="btn btn-round btn-success"
                (click)="onAddNewPayment()"
                style="padding-bottom: 0.15rem !important"
                matTooltip="Добавить банковскую карту"
                matTooltipClass="tooltip"
              >
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>
        </mat-card-title>
        <p-table
          [value]="userPaymentList"
          styleClass=" p-datatable-sm p-datatable-gridlines p-datatable-striped"
          [scrollable]="true"
          scrollHeight="150px"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>Номер карты</th>
              <th>Владелец</th>
              <th style="width: 7rem"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product>
            <tr>
              <td class="py-1" style="font-size: 14px">{{ product.number }}</td>
              <td class="py-1" style="font-size: 14px">
                {{ product.userName }}
              </td>
              <td class="py-1 text-center" style="width: 7rem">
                <button
                  type="button"
                  mat-icon-button
                  class="text-gray"
                  (click)="
                    onEditBankCard(product.id, product.number, product.userName)
                  "
                  matTooltip="Редактировать банковскую карту"
                  matTooltipClass="tooltip"
                >
                  <mat-icon style="font-size: 14px">edit</mat-icon>
                </button>
                <button
                  type="button"
                  mat-icon-button
                  class="text-gray"
                  (click)="onDeleteBankCard(product.id)"
                  matTooltip="Удалить банковскую карту"
                  matTooltipClass="tooltip"
                >
                  <mat-icon style="font-size: 14px">delete</mat-icon>
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </mat-card>
    </div>
    <div class="col-12 col-md-5">
      <mat-card
        class="card-box"
        *ngIf="userCallList$ | async as userCallList"
        style="min-height: 16.25rem"
      >
        <mat-card-title>
          <div class="row justify-content-between">
            <div class="col-auto"><b>Журнал звонков</b></div>
          </div>
        </mat-card-title>
        <p-table
          [value]="userCallList"
          styleClass=" p-datatable-sm p-datatable-gridlines p-datatable-striped"
          [scrollable]="true"
          scrollHeight="150px"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>Дата</th>
              <th>Телефон</th>
              <th>Менеджер</th>
              <th>Тип</th>
              <th style="width: 7rem"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product>
            <tr>
              <td class="py-1">
                {{ product.date | date: "dd-MM-YYYY HH:mm" }}
              </td>
              <td class="py-1">{{ srv.hidePhone(product.phone) }}</td>
              <td class="py-1">{{ product.manager }}</td>
              <td class="py-1">{{ product.type }}</td>
              <td style="width: 7rem" class="py-1 text-center">
                <button
                  type="button"
                  mat-button
                  class="btn btn-success btn-sm"
                  style="padding-bottom: 0.6rem !important"
                  (click)="callClient(product.phone)"
                  [matTooltip]="'Позвонить'"
                  matTooltipClass="tooltip"
                >
                  <mat-icon style="font-size: 14px !important">phone</mat-icon>
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </mat-card>

      <mat-card
        class="card-box mt-2"
        *ngIf="contactList$ | async as contactList"
        style="min-height: 16.25rem"
      >
        <mat-card-title>
          <div class="row justify-content-between">
            <div class="col-auto"><b>Последние 3 контакта</b></div>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div
            class="row my-2 hover align-items-center"
            *ngFor="let item of contactList"
          >
            <div class="col-1 py-1 text-center">
              <div [ngSwitch]="item.iconType">
                <mat-icon mat-list-icon *ngSwitchCase="1" class="text-gray"
                  >textsms</mat-icon
                >
                <mat-icon mat-list-icon *ngSwitchCase="2" class="text-gray"
                  >info</mat-icon
                >
              </div>
            </div>
            <div class="col-11 py-1">
              <div mat-line>{{ item.message }}</div>
              <div mat-line class="text-gray mt-1">
                {{ item.date | date: "dd-MM-YYYY HH:ss" }} | {{ item.manager }}
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="d-flex justify-content-center my-5" style="height: 600px">
    <mat-spinner diameter="25"></mat-spinner>
  </div>
</ng-template>

<div
  class="container-fluid"
  *ngIf="userOrderData$ | async as userOrderData; else loading2"
>
  <div class="row mt-2">
    <div class="col">
      <mat-tab-group>
        <mat-tab>
          <ng-template mat-tab-label>
            <div class="h3 text-dark">Заказы</div>
          </ng-template>

          <p-table
            #dt
            [columns]="userOrderData.order.cols"
            [value]="userOrderData.order.rows"
            [rowsPerPageOptions]="[10, 25, 50, 100]"
            [paginator]="true"
            [globalFilterFields]="[
              'orderNumber',
              'dateCreate',
              'cost',
              'carName',
              'orderAction',
              'manager'
            ]"
            currentPageReportTemplate="Элементов на странице ({first} - {last}) з {totalRecords} "
            [filterDelay]="0"
            autoLayout="true"
            [rows]="10"
            dataKey="orderNumber"
            selectionMode="single"
            styleClass="p-datatable-gridlines p-datatable-sm"
          >
            <ng-template pTemplate="caption">
              <div class="row justify-content-between">
                <div class="col-auto">
                  <button
                    mat-button
                    class="btn btn-primary"
                    [matMenuTriggerFor]="menu"
                  >
                    Создать заказ
                  </button>
                  <mat-menu #menu="matMenu" yPosition="above">
                    <button mat-menu-item (click)="createNewOrder(7)">
                      Телефон
                    </button>
                    <button mat-menu-item (click)="createNewOrder(10)">
                      Viber
                    </button>
                    <button mat-menu-item (click)="createNewOrder(20)">
                      Чат на сайте
                    </button>
                  </mat-menu>
                </div>
                <div class="col-auto">
                  <input
                    pInputText
                    type="text"
                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                    placeholder="Поиск"
                  />
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th></th>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                  {{ col.header }}
                </th>
              </tr>
            </ng-template>
            <ng-template
              pTemplate="body"
              let-rowData
              let-columns="columns"
              let-expanded="expanded"
            >
              <tr>
                <td>
                  <button
                    type="button"
                    pButton
                    [pRowToggler]="rowData"
                    class="p-button-text p-button-rounded p-button-plain"
                    [icon]="
                      expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
                    "
                  ></button>
                </td>
                <td *ngFor="let col of columns">
                  <div [ngSwitch]="col.field">
                    <div *ngSwitchCase="'orderNumber'">
                      <a
                        [href]="'/crm/order-card/' + rowData.orderNumber"
                        mat-button
                        class="btn btn-success"
                        >{{ rowData.orderNumber }}</a
                      >
                    </div>
                    <div *ngSwitchDefault>
                      {{ rowData[col.field] }}
                    </div>
                  </div>
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="rowexpansion" let-product>
              <tr>
                <td colspan="8" class="px-5">
                  <p-table [value]="product.prod" dataKey="name" class="mx-3">
                    <ng-template pTemplate="header">
                      <tr>
                        <th pSortableColumn="name">
                          Название <p-sortIcon field="name"></p-sortIcon>
                        </th>
                        <th pSortableColumn="katalNumber">
                          Каталожный
                          <p-sortIcon field="katalNumber"></p-sortIcon>
                        </th>
                        <th pSortableColumn="brand">
                          Бренд <p-sortIcon field="brand"></p-sortIcon>
                        </th>
                        <th pSortableColumn="count">
                          Кол-во <p-sortIcon field="count"></p-sortIcon>
                        </th>
                        <th pSortableColumn="cost">
                          Цена отгрузки <p-sortIcon field="cost"></p-sortIcon>
                        </th>
                        <th pSortableColumn="cost">
                          Оплата товара <p-sortIcon field="costPaymentProd"></p-sortIcon>
                        </th>
                        <th pSortableColumn="action">
                          Статус <p-sortIcon field="action"></p-sortIcon>
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-order>
                      <tr>
                        <td>{{ order.name }}</td>
                        <td>{{ order.katalNumber }}</td>
                        <td>{{ order.brand }}</td>
                        <td>{{ order.count }}</td>
                        <td>{{ order.cost }}</td>
                        <td>{{ order.costPaymentProd }}</td>
                        <td>{{ order.action }}</td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td colspan="6">В заказе нет добавленых товаров</td>
                      </tr>
                    </ng-template>
                  </p-table>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <div class="h3 text-dark">Автомобили</div>
          </ng-template>
          <p-table
            #dt2
            [columns]="userOrderData.cars.cols"
            [value]="userOrderData.cars.rows"
            [rowsPerPageOptions]="[10, 25, 50, 100]"
            [paginator]="true"
            [globalFilterFields]="[
              'vinnumber',
              'mark',
              'model',
              'years',
              'body',
              'engine',
              'modification'
            ]"
            currentPageReportTemplate="Элементов на странице ({first} - {last}) з {totalRecords} "
            [filterDelay]="0"
            autoLayout="true"
            [rows]="10"
            selectionMode="single"
            styleClass="p-datatable-gridlines p-datatable-sm"
          >
            <ng-template pTemplate="caption">
              <div class="row justify-content-between">
                <div class="col-auto">
                  <button
                    type="button"
                    class="btn btn-success"
                    (click)="addNewUserCar()"
                    mat-button
                  >
                    Добавить авто
                  </button>
                </div>
                <div class="col-auto">
                  <input
                    pInputText
                    type="text"
                    (input)="dt2.filterGlobal($event.target.value, 'contains')"
                    placeholder="Поиск"
                  />
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                  {{ col.header }}
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr>
                <td *ngFor="let col of columns">
                  <div [ngSwitch]="col.field">
                    <div
                      *ngSwitchCase="'vinnumber'"
                      class="copy-content"
                      style="position: relative"
                    >
                      {{ rowData.vinnumber }}
                      <button
                        *ngIf="rowData.vinnumber"
                        [cdkCopyToClipboard]="rowData.vinnumber"
                        (click)="trueCopy()"
                        class="copy-content__btn mat-icon-button"
                        mat-icon-button
                        matTooltip="Скопировать VIN Код"
                        matTooltipClass="tooltip"
                        matTooltipPosition="below"
                      >
                        <span class="mat-button-wrapper"
                          ><mat-icon
                            class="material-icons_grey copy-content__icon mat-icon notranslate material-icons mat-icon-no-color"
                            role="img"
                            aria-hidden="true"
                            >content_copy</mat-icon
                          >
                        </span>
                      </button>
                    </div>
                    <div *ngSwitchCase="'action'" class="text-center">
                      <button
                        *ngIf="rowData.mark && rowData.model"
                        mat-button
                        class="btn btn-sm btn-success"
                        matTooltip="Создать заказ"
                        (click)="onCreateNewOrderByCar(rowData.id)"
                        matTooltipClass="tooltip"
                      >
                        <mat-icon style="font-size: 16px"
                          >shopping_cart</mat-icon
                        >
                      </button>
                      <button
                        mat-icon-button
                        class="ml-2"
                        matTooltip="Редактировать Автомобиль"
                        matTooltipClass="tooltip"
                        (click)="onEditCar(rowData.id)"
                      >
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button
                        mat-icon-button
                        class="ml-2"
                        matTooltip="Удалить Автомобиль"
                        matTooltipClass="tooltip"
                        (click)="onDeleteCar(rowData.id)"
                      >
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                    <div *ngSwitchDefault>
                      {{ rowData[col.field] }}
                    </div>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <div class="h3 text-dark">Претензии</div>
          </ng-template>
          <p-table
            #dt3
            [columns]="userOrderData.pretension.cols"
            [value]="userOrderData.pretension.rows"
            [rowsPerPageOptions]="[10, 25, 50, 100]"
            [paginator]="true"
            [globalFilterFields]="[
              'orderNumber',
              'dateCreate',
              'cost',
              'carName',
              'orderAction',
              'manager'
            ]"
            currentPageReportTemplate="Элементов на странице ({first} - {last}) з {totalRecords} "
            [filterDelay]="0"
            autoLayout="true"
            [rows]="10"
            selectionMode="single"
            styleClass="p-datatable-gridlines p-datatable-sm"
          >
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                  {{ col.header }}
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr>
                <td *ngFor="let col of columns">
                  <div [ngSwitch]="col.field">
                    <div *ngSwitchCase="'orderNumber'">
                      <a
                        [href]="'/crm/order-card/' + rowData.orderNumber"
                        target="_blank"
                        mat-button
                        class="btn btn-success"
                        >{{ rowData.orderNumber }}</a
                      >
                    </div>
                    <div *ngSwitchCase="'act'">
                      <a
                        [href]="'/crm/pretension/' + rowData.id"
                        target="_blank"
                        mat-button
                      >
                        <mat-icon class="text-gray">more_vert</mat-icon>
                      </a>
                    </div>
                    <div *ngSwitchDefault>
                      {{ rowData[col.field] }}
                    </div>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
<ng-template #loading2>
  <div class="d-flex justify-content-center my-5" style="height: 600px">
    <mat-spinner diameter="25"></mat-spinner>
  </div>
</ng-template>
