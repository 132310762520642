import {Component, OnInit, ViewChild} from '@angular/core';
import {ProdProfitService} from './prod-profit.service';
import {FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {OtherSpr} from '../../../core/interfaces/interfaces';
import {MatOption} from '@angular/material/core';

@Component({
  selector: 'app-prod-profit',
  templateUrl: './prod-profit.component.html',
  styleUrls: ['./prod-profit.component.scss'],
  providers: [ProdProfitService]
})
export class ProdProfitComponent implements OnInit {
  form: FormGroup;

  prod: Observable<any>;

  constructor(
    private pps: ProdProfitService
  ) {
    this.form = new FormGroup({
      provider: new FormControl(),
      brand: new FormControl(),
    });

  }

  // для выбора всего списка соответсвенно
  @ViewChild('allSelectedProvider') private allSelectedProvider: MatOption;

  ngOnInit(): void {
  }

  getProdInfo(): void{
    this.prod = this.pps.getProdInfo();
  }
}
