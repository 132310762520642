<button mat-button class="btn-block" (click)="showTaskList()">
  <div class="d-md-block d-none">
    <div class="row align-items-center">
      <div class="col-2" style="padding-top: 1rem">
        <mat-icon>comment_bank</mat-icon>
      </div>
      <div
        class="col-10 bg-danger"
        style="font-size: 20px !important; color: #ffffff !important"
      >
        {{ taskIndicator.countTask }}
        ({{ taskIndicator.missedTaskList }}) |
        {{ taskIndicator.countPres }}
        ({{ taskIndicator.missedPresList }})|
        {{ taskIndicator.requestCount }}
        <span *ngIf="showRandomProdList && showRandomProdList.length > 0">
          |
          {{ taskIndicator.randomProduct }}
          ({{ taskIndicator.missedRandomProduct }})</span
        >
      </div>
    </div>
  </div>
  <div class="d-block d-md-none">
    <span
      class="btn btn-danger"
      style="display: inline-block; font-size: 20px !important"
    >
      {{ taskIndicator.countTask }}
      ({{ taskIndicator.missedTaskList }}) |
      {{ taskIndicator.countPres }}
      ({{ taskIndicator.missedPresList }})|
      {{ taskIndicator.requestCount }}
      <span *ngIf="showRandomProdList && showRandomProdList.length > 0">
        |
        {{ taskIndicator.randomProduct }}
        ({{ taskIndicator.missedRandomProduct }})</span
      >
    </span>
  </div>
</button>
