<mat-card-title>
  <div class="row justify-content-between mb-3">
    <div class="col-auto h2"><b>Добавить карточку получателя.</b></div>
    <div class="col-auto">
      <button type="button" mat-icon-button class="btn btn-primary btn-sm"
              matTooltip="Закрыть"
              matTooltipPosition="left"
              matTooltipClass="tooltip"
              matDialogClose>
        <img src="assets/img/icon/close/Icon_Close.svg" alt="close">
      </button>
    </div>
  </div>
</mat-card-title>

<mat-card-content>
  <div class="row">
    <div class="col">
      <form [formGroup]="formRecipient">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>Телефон<span class="text-danger">*</span></mat-label>
          <input matInput
                 type="text"
                 prefix="+38"
                 [mask]="' (000) 000-00-99'"
                 formControlName="phone"
          />
          <mat-error *ngIf="formRecipient.get('phone').hasError('required')">
            Введите телефон
          </mat-error>
          <mat-error *ngIf="formRecipient.get('phone').hasError('minlength')">
            Номер телефона некорректный
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-label>Имя</mat-label>
          <input matInput
                 type="text"
                 formControlName="name"
          />
          <mat-error *ngIf="formRecipient.get('name').hasError('required')">
            Введите имя получателя
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-label>Фамилия</mat-label>
          <input matInput
                 type="text"
                 formControlName="lastName"
          />
          <mat-error *ngIf="formRecipient.get('lastName').hasError('required')">
            Введите фамилию получателя
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-label>Отчество</mat-label>
          <input matInput
                 type="text"
                 formControlName="middleName"
          />

        </mat-form-field>
      </form>
    </div>
  </div>
</mat-card-content>
<mat-dialog-actions align="end">
  <button mat-button class="btn btn-danger" type="button" matDialogClose>Отмена</button>
  <button mat-button class="btn btn-success" type="button" (click)="editRecipient()">Сохранить</button>
</mat-dialog-actions>
