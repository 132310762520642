import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {RsService} from '../core/rs.service';
import {Observable} from 'rxjs';
import {RomiDialogModelComponent} from '../romi-dialog-model/romi-dialog-model.component';

@Component({
  selector: 'app-dialog-mark',
  templateUrl: './dialog-mark.component.html',
  styleUrls: ['./dialog-mark.component.scss'],
  providers: [RsService]
})
export class DialogMarkComponent implements OnInit {

  infoList$: Observable<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private rs: RsService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.loadInfoMark();
  }

  loadInfoMark(): void {
    this.infoList$ = this.rs.loadInfoMark(
      this.dialogData.mark,
      this.dialogData.dateStart,
      this.dialogData.dateEnd);
  }

  showModelInfo(filterId: any): void {

    this.dialog.open(RomiDialogModelComponent, {
      minWidth: '70vw',
      maxHeight: '90vh',
      data: {
        model: filterId,
        mark: this.dialogData.mark,
        dateStart: this.dialogData.dateStart,
        dateEnd: this.dialogData.dateEnd
      }
    });

  }
}
