<mat-card-title>
  <div class="row justify-content-between mb-3">
    <div class="col-auto h2"><b>{{dialogData.katalNumber}} {{dialogData.brand}}</b></div>
    <div class="col-auto">
      <button type="button" mat-icon-button class="btn btn-primary btn-sm"
              matTooltip="Закрыть"
              matTooltipPosition="left"
              matTooltipClass="tooltip"
              matDialogClose>
        <img src="assets/img/icon/close/Icon_Close.svg" alt="close">
      </button>
    </div>
  </div>
</mat-card-title>
<mat-card-content>
  <div class="row" *ngFor="let item of dialogData.itemList">
    <div class="col">
      <app-provider-count-table [providerInfo]="item"></app-provider-count-table>
    </div>
  </div>
</mat-card-content>
