<div class="container-fluid">
  <div class="row my-3" *ngIf="pretensionInfo$ | async as taskInfo">
    <div class="col">
      <mat-card class="card-box">
        <mat-card-title>
          <div class="row justify-content-between">
            <div class="col-auto">
              Претензия № {{ pretensionId }} по заказу
              <a
                *ngIf="taskInfo.orderNumber"
                [href]="'/crm/order-card/' + taskInfo.orderNumber"
                class="text-primary"
                target="_blank"
                >{{ taskInfo.orderNumber }}</a
              >
              <a
                *ngIf="taskInfo.hash"
                [href]="'/crm/user-card/' + taskInfo.hash"
                mat-button
                target="_blank"
                class="btn btn-primary ml-3"
                >{{ "SALE.TASK.PRETENSION_CARD.CLIENT_CARD" | translate }}</a
              >
              ({{ taskInfo.taskAction }})
            </div>
            <div class="col-auto" *ngIf="workmanSelfId == taskInfo.workman">
              <div [ngSwitch]="taskInfo.action">
                <div *ngSwitchCase="1">
                  <button
                    type="button"
                    mat-button
                    (click)="setPretensionToWork()"
                    class="btn btn-success"
                  >
                    {{ "SALE.TASK.PRETENSION_CARD.BTN_TO_WORK" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div class="row justify-content-between">
            <div class="col-12 col-md-5">
              <mat-card class="card-box">
                <mat-card-subtitle>{{
                  "SALE.TASK.PRETENSION_CARD.TASK" | translate
                }}</mat-card-subtitle>
                <mat-card-title>
                  <b>{{ taskInfo.task }}</b>
                </mat-card-title>
              </mat-card>

              <mat-card class="card-box">
                <mat-card-subtitle>Автор</mat-card-subtitle>
                <mat-card-subtitle>
                  <b>{{ taskInfo.authorName }}</b>
                </mat-card-subtitle>
              </mat-card>
            </div>
            <div class="col-12 col-md-5">
              <div class="row">
                <div class="col">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label>{{
                      "SALE.TASK.PRETENSION_CARD.DATE_BEGIN" | translate
                    }}</mat-label>
                    <input
                      matInput
                      type="datetime-local"
                      [formControl]="dateBegin"
                      readonly
                    />
                  </mat-form-field>
                </div>
                <div class="col">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label>{{
                      "SALE.TASK.PRETENSION_CARD.DATE_END" | translate
                    }}</mat-label>
                    <input
                      matInput
                      type="datetime-local"
                      [formControl]="dateEnd"
                      readonly
                    />
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <mat-form-field
                    appearance="outline"
                    style="width: 100%"
                    *ngIf="categoryList$ | async as categoryList"
                  >
                    <mat-label>{{
                      "SALE.TASK.PRETENSION_CARD.CATEGORY" | translate
                    }}</mat-label>
                    <mat-select [formControl]="category" disabled>
                      <mat-option
                        *ngFor="let item of categoryList"
                        [value]="+item.value"
                        >{{ item.text }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field
                    appearance="outline"
                    style="width: 100%"
                    *ngIf="subCategoryList"
                  >
                    <mat-label>{{
                      "SALE.TASK.PRETENSION_CARD.SUBREASON" | translate
                    }}</mat-label>
                    <mat-select [formControl]="subCategory" disabled>
                      <mat-option
                        *ngFor="let item of subCategoryList"
                        [value]="item.value"
                        >{{ item.text }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label>{{
                      "SALE.TASK.PRETENSION_CARD.MANAGER" | translate
                    }}</mat-label>
                    <input matInput [formControl]="manager" readonly />
                  </mat-form-field>

                  <button
                    *ngIf="
                      showEditManager &&
                      +taskInfo.action != 3 &&
                      showEditManager.length > 0
                    "
                    mat-button
                    class="btn btn-primary"
                    (click)="editManager()"
                  >
                    Изменить исполнителя
                  </button>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card
        class="card-box my-2"
        *ngIf="
          category.value != 2 && category.value != 3 && category.value != 6
        "
      >
        <app-disable-block
          *ngIf="+taskInfo.action != 2 || +taskInfo.step1 == 1"
        ></app-disable-block>
        <mat-card-title>
          {{ "SALE.TASK.PRETENSION_CARD.CHECKED" | translate }}
        </mat-card-title>
        <mat-card-content>
          <div class="row">
            <div class="col-12 col-md-4">
              <div class="row mb-4">
                <div class="col-3">
                  <u>{{ "SALE.TASK.PRETENSION_CARD.PATH" | translate }}</u>
                </div>
                <div class="col-9">{{ taskInfo.path }}</div>
              </div>
              <div class="row mb-4">
                <div class="col-3">
                  <u>{{ "SALE.TASK.PRETENSION_CARD.CAR" | translate }}</u>
                </div>
                <div class="col-9" [innerHTML]="taskInfo.car"></div>
              </div>
            </div>
            <div class="col-12 col-md-8">
              <p-table
                #dt1
                [value]="taskInfo.productList"
                [rows]="25"
                styleClass=" p-datatable-sm p-datatable-gridlines p-datatable-striped"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 10rem" class="text-center">
                      {{
                        "SALE.TASK.PRETENSION_CARD.PROD_TABLE.OE" | translate
                      }}
                    </th>
                    <th style="width: 5rem" class="text-center">
                      {{
                        "SALE.TASK.PRETENSION_CARD.PROD_TABLE.DAYS" | translate
                      }}
                    </th>
                    <th style="width: 5rem" class="text-center">
                      {{
                        "SALE.TASK.PRETENSION_CARD.PROD_TABLE.KATAL" | translate
                      }}
                    </th>
                    <th style="width: 5rem" class="text-center">
                      {{
                        "SALE.TASK.PRETENSION_CARD.PROD_TABLE.BRAND" | translate
                      }}
                    </th>
                    <th style="width: 5rem" class="text-center">
                      {{
                        "SALE.TASK.PRETENSION_CARD.PROD_TABLE.MANAGER"
                          | translate
                      }}
                    </th>
                    <th style="width: 5rem" class="text-center">
                      {{
                        "SALE.TASK.PRETENSION_CARD.PROD_TABLE.ACTION"
                          | translate
                      }}
                    </th>
                    <th style="width: 5rem" class="text-center">
                      {{
                        "SALE.TASK.PRETENSION_CARD.PROD_TABLE.STATUS"
                          | translate
                      }}
                    </th>
                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-task>
                  <tr style="font-size: 12px">
                    <td>{{ task.original }} {{ task.setYes }}</td>
                    <td>{{ task.days }}</td>
                    <td>{{ task.katalNumber }}</td>
                    <td>{{ task.brand }}</td>
                    <td>{{ task.manager }}</td>
                    <td>{{ task.actionText }}</td>
                    <td>
                      <mat-slide-toggle
                        color="primary"
                        [(ngModel)]="task.acceptAction"
                      >
                        {{
                          task.acceptAction
                            ? ("SALE.TASK.PRETENSION_CARD.PROD_TABLE.TRUE"
                              | translate)
                            : ("SALE.TASK.PRETENSION_CARD.PROD_TABLE.FALSE"
                              | translate)
                        }}</mat-slide-toggle
                      >
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </mat-card-content>
        <mat-card-actions align="end">
          <button
            type="button"
            mat-button
            class="btn btn-success"
            *ngIf="+taskInfo.step1 == 0"
            (click)="onSaveChecked()"
          >
            {{ "SALE.TASK.PRETENSION_CARD.BTN_SAVE" | translate }}
          </button>
        </mat-card-actions>
      </mat-card>

      <mat-card
        class="card-box my-2"
        *ngIf="category.value != 2 && category.value != 3"
      >
        <app-disable-block
          *ngIf="+taskInfo.action != 2 || +taskInfo.step2 == 1"
        ></app-disable-block>
        <mat-card-title>
          {{ "SALE.TASK.PRETENSION_CARD.DECISION" | translate }}
        </mat-card-title>
        <form [formGroup]="formDecision" (ngSubmit)="onSaveDecision()">
          <mat-card-content>
            <div class="row">
              <div class="col-12 col-md-4">
                <h2 class="h2">
                  {{
                    "SALE.TASK.PRETENSION_CARD.WHAT_PRODUCT.TITLE" | translate
                  }}
                </h2>
                <mat-radio-group
                  formControlName="product"
                  (ngModelChange)="changePath()"
                >
                  <mat-radio-button
                    class="my-2"
                    [value]="1"
                    *ngIf="
                      +category.value == 1 ||
                      +category.value == 6 ||
                      +category.value == 7 ||
                      +category.value == 8
                    "
                    >{{
                      "SALE.TASK.PRETENSION_CARD.WHAT_PRODUCT.TEXT_1"
                        | translate
                    }}</mat-radio-button
                  >
                  <br />
                  <mat-radio-button
                    class="my-2"
                    [value]="2"
                    *ngIf="
                      +category.value == 1 ||
                      +category.value == 6 ||
                      +category.value == 7 ||
                      +category.value == 8
                    "
                    >{{
                      "SALE.TASK.PRETENSION_CARD.WHAT_PRODUCT.TEXT_2"
                        | translate
                    }}</mat-radio-button
                  >
                  <br />
                  <mat-radio-button
                    class="my-2"
                    [value]="3"
                    *ngIf="+category.value == 5"
                    >{{
                      "SALE.TASK.PRETENSION_CARD.WHAT_PRODUCT.TEXT_3"
                        | translate
                    }}</mat-radio-button
                  >
                  <br />
                  <mat-radio-button
                    class="my-2"
                    [value]="4"
                    *ngIf="
                      +category.value == 1 ||
                      +category.value == 6 ||
                      +category.value == 7 ||
                      +category.value == 8
                    "
                    >{{
                      "SALE.TASK.PRETENSION_CARD.WHAT_PRODUCT.TEXT_4"
                        | translate
                    }}</mat-radio-button
                  >

                  <br />
                  <mat-radio-button
                    class="my-2"
                    [value]="5"
                    *ngIf="
                      +category.value == 1 ||
                      +category.value == 6 ||
                      +category.value == 7 ||
                      +category.value == 5 ||
                      +category.value == 8
                    "
                    >Отмена претензии по согласованию сторон</mat-radio-button
                  >
                </mat-radio-group>
              </div>
              <div
                class="col-12 col-md-4"
                *ngIf="
                  +formDecision.get('product').value != 4 &&
                  +formDecision.get('product').value != 5
                "
              >
                <h2 class="h2">
                  {{
                    "SALE.TASK.PRETENSION_CARD.WHAT_PAYMENT.TITLE" | translate
                  }}
                </h2>
                <mat-radio-group
                  formControlName="payment"
                  (ngModelChange)="setPayment()"
                >
                  <mat-radio-button class="my-2" [value]="1">{{
                    "SALE.TASK.PRETENSION_CARD.WHAT_PAYMENT.TEXT_1" | translate
                  }}</mat-radio-button>
                  <br />
                  <mat-radio-button class="my-2" [value]="2">{{
                    "SALE.TASK.PRETENSION_CARD.WHAT_PAYMENT.TEXT_2" | translate
                  }}</mat-radio-button>
                  <br />

                  <div
                    *ngIf="+formDecision.get('payment').value == 2"
                    class="pl-3"
                    style="width: 80%"
                  >
                    <mat-form-field appearance="outline" style="width: 100%">
                      <mat-label>{{
                        "SALE.TASK.PRETENSION_CARD.WHAT_PAYMENT.TEXT_4"
                          | translate
                      }}</mat-label>
                      <mat-select formControlName="card">
                        <mat-option
                          *ngFor="let item of taskInfo.bankCard"
                          [value]="item.value"
                          >{{ item.text }}</mat-option
                        >
                      </mat-select>
                      <mat-error
                        *ngIf="formDecision.get('card').hasError('required')"
                      >
                        {{
                          "SALE.TASK.PRETENSION_CARD.WHAT_PAYMENT.TEXT_4"
                            | translate
                        }}
                      </mat-error>
                    </mat-form-field>
                    <br />
                    <mat-checkbox
                      color="primary"
                      formControlName="cardReq"
                      (change)="notReqCard()"
                    >
                      {{
                        "SALE.TASK.PRETENSION_CARD.WHAT_PAYMENT.TEXT_5"
                          | translate
                      }}
                    </mat-checkbox>
                  </div>

                  <mat-radio-button class="my-2" [value]="3">{{
                    "SALE.TASK.PRETENSION_CARD.WHAT_PAYMENT.TEXT_3" | translate
                  }}</mat-radio-button>
                  <br />

                  <mat-radio-button class="my-2" [value]="4">{{
                    "SALE.TASK.PRETENSION_CARD.WHAT_PAYMENT.TEXT_6" | translate
                  }}</mat-radio-button>
                  <br />
                  <mat-radio-button
                    class="my-2"
                    [value]="5"
                    *ngIf="taskInfo.orderList.length > 0"
                    >Подвязать к заказу</mat-radio-button
                  >

                  <div
                    *ngIf="+formDecision.get('payment').value == 5"
                    class="pl-3"
                    style="width: 80%"
                  >
                    <mat-form-field appearance="outline" style="width: 100%">
                      <mat-label>{{
                        "SALE.TASK.PRETENSION_CARD.WHAT_PAYMENT.TEXT_4"
                          | translate
                      }}</mat-label>
                      <mat-select formControlName="orderNumber">
                        <mat-option
                          *ngFor="let item of taskInfo.orderList"
                          [value]="item.zakaz"
                          >{{ item.zakaz }}</mat-option
                        >
                      </mat-select>
                      <mat-error
                        *ngIf="formDecision.get('card').hasError('required')"
                        >Выберите заказ для сохранения оплаты
                      </mat-error>
                    </mat-form-field>
                  </div>
                </mat-radio-group>
              </div>
              <div
                class="col-12 col-md-4"
                *ngIf="
                  +formDecision.get('product').value != 4 &&
                  +formDecision.get('product').value != 5
                "
              >
                <mat-form-field
                  appearance="outline"
                  *ngIf="+taskInfo.deliveryId != 6 && +taskInfo.deliveryId != 7"
                  style="width: 100%"
                >
                  <mat-label>{{
                    "SALE.TASK.PRETENSION_CARD.RETURN_TTN" | translate
                  }}</mat-label>
                  <input matInput formControlName="returnTtn" />
                  <mat-error
                    *ngIf="formDecision.get('returnTtn').hasError('required')"
                  >
                    {{ "SALE.TASK.PRETENSION_CARD.RETURN_TTN_ERR" | translate }}
                  </mat-error>
                </mat-form-field>
                <br />
                <mat-checkbox
                  *ngIf="+taskInfo.deliveryId != 6 && +taskInfo.deliveryId != 7"
                  color="primary"
                  formControlName="returnTtnReq"
                  (change)="notReqReturnTtn()"
                  >{{
                    "SALE.TASK.PRETENSION_CARD.RETURN_TTN_AFTER" | translate
                  }}</mat-checkbox
                >

                <div class="row my-3">
                  <div class="col">
                    <mat-divider></mat-divider>
                  </div>
                </div>

                <mat-checkbox color="primary" formControlName="sendSms">{{
                  "SALE.TASK.PRETENSION_CARD.SEND_SMS" | translate
                }}</mat-checkbox>

                <div class="row my-3">
                  <div class="col">
                    <mat-divider></mat-divider>
                  </div>
                </div>

                <mat-radio-group
                  formControlName="alt"
                  (ngModelChange)="changeAlt()"
                >
                  <mat-radio-button class="my-2" [value]="1">{{
                    "SALE.TASK.PRETENSION_CARD.ALT_PROD.TEXT_1" | translate
                  }}</mat-radio-button>
                  <br />
                  <mat-radio-button class="my-2" [value]="2">{{
                    "SALE.TASK.PRETENSION_CARD.ALT_PROD.TEXT_2" | translate
                  }}</mat-radio-button>
                </mat-radio-group>

                <div *ngIf="+formDecision.get('alt').value == 1">
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <mat-form-field
                        appearance="outline"
                        style="width: 100%"
                        *ngIf="altOrderList$ | async as altOrderList"
                      >
                        <mat-label>{{
                          "SALE.TASK.PRETENSION_CARD.ALT_PROD.ALT_ORDER"
                            | translate
                        }}</mat-label>
                        <mat-select
                          formControlName="altOrder"
                          (selectionChange)="loadAltProduct()"
                        >
                          <mat-option
                            *ngFor="let item of altOrderList"
                            [value]="item.value"
                            >{{ item.text }}</mat-option
                          >
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-12 col-md-8">
                      <mat-form-field
                        appearance="outline"
                        style="width: 100%"
                        *ngIf="altProductList$ | async as altProductList"
                      >
                        <mat-label>{{
                          "SALE.TASK.PRETENSION_CARD.ALT_PROD.ALT_ORDER"
                            | translate
                        }}</mat-label>
                        <mat-select formControlName="altProd" multiple>
                          <mat-option
                            *ngFor="let item of altProductList"
                            [value]="item.value"
                            >{{ item.katalNumber }} {{ item.brand }}</mat-option
                          >
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <mat-form-field
                  appearance="outline"
                  style="width: 100%"
                  *ngIf="+formDecision.get('alt').value == 2"
                >
                  <mat-label>{{
                    "SALE.TASK.PRETENSION_CARD.COMMENTS" | translate
                  }}</mat-label>
                  <textarea
                    rows="5"
                    matInput
                    formControlName="comment"
                  ></textarea>
                  <mat-error *ngIf="form.get('comment').hasError('required')">
                    {{ "SALE.TASK.PRETENSION_CARD.COMMENTS_ERR" | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div
                class="col-auto"
                *ngIf="
                  +formDecision.get('product').value != 4 &&
                  +formDecision.get('product').value != 5
                "
              >
                <mat-form-field
                  appearance="outline"
                  style="width: 100%"
                  *ngIf="categoryList$ | async as categoryList"
                >
                  <mat-label>{{
                    "SALE.TASK.PRETENSION_CARD.CATEGORY" | translate
                  }}</mat-label>
                  <mat-select
                    [formControl]="category"
                    (selectionChange)="changeReason()"
                  >
                    <mat-option
                      *ngFor="let item of categoryList"
                      [value]="+item.value"
                      >{{ item.text }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>

                <mat-form-field
                  appearance="outline"
                  style="width: 100%"
                  *ngIf="subCategoryList"
                >
                  <mat-label>{{
                    "SALE.TASK.PRETENSION_CARD.SUBREASON" | translate
                  }}</mat-label>
                  <mat-select [formControl]="subCategory">
                    <mat-option
                      *ngFor="let item of subCategoryList"
                      [value]="item.value"
                      >{{ item.text }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-3">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Комментарий</mat-label>
                  <textarea
                    matInput
                    [formControl]="comment"
                    rows="3"
                  ></textarea>
                </mat-form-field>
              </div>
            </div>
            <div
              class="row"
              *ngIf="
                +category.value == 8 &&
                +formDecision.get('product').value != 5 &&
                +formDecision.get('product').value != 4
              "
            >
              <div class="col-auto">
                <mat-form-field appearance="outline">
                  <mat-label>Каталожный номер</mat-label>
                  <input matInput formControlName="katalNumber" />
                </mat-form-field>
              </div>
              <div class="col-auto">
                <mat-form-field appearance="outline">
                  <mat-label>Бренд</mat-label>
                  <input matInput formControlName="brand" />
                </mat-form-field>
              </div>
              <div class="col-auto">
                <mat-form-field appearance="outline">
                  <mat-label>Неверный Каталожный кросс </mat-label>
                  <input matInput formControlName="katalNumberCross" />
                </mat-form-field>
              </div>
              <div class="col-auto">
                <mat-form-field appearance="outline">
                  <mat-label>Неверный Бренд кросс </mat-label>
                  <input matInput formControlName="brandCross" />
                </mat-form-field>
              </div>
            </div>
          </mat-card-content>
          <mat-card-actions align="end">
            <button
              type="submit"
              mat-button
              class="btn btn-success"
              *ngIf="+taskInfo.step2 == 0"
            >
              {{ "SALE.TASK.PRETENSION_CARD.BTN_SAVE" | translate }}
            </button>
          </mat-card-actions>
        </form>
      </mat-card>
      <mat-card class="card-box my-2">
        <mat-card-title>
          {{ "SALE.TASK.PRETENSION_CARD.CONFIRM" | translate }}
        </mat-card-title>
        <mat-card-content>
          <div class="row">
            <div class="col-12 col-md-6" *ngIf="taskInfo.returnProduct">
              <h2 class="h2">
                {{
                  "SALE.TASK.PRETENSION_CARD.PRODUCT_RETURN_LIST_ACTION"
                    | translate
                }}
              </h2>
              <div
                class="row hover p-2"
                *ngFor="let item2 of taskInfo.returnProduct"
              >
                <div class="col">
                  {{ item2.katalNumber }} {{ item2.brand }} -
                  <b>{{ item2.action }}</b>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6" *ngIf="taskInfo.taskList">
              <h2 class="h2">
                {{
                  "SALE.TASK.PRETENSION_CARD.TASK_RETURN_LIST_ACTION"
                    | translate
                }}
              </h2>
              <div
                class="row hover p-2"
                *ngFor="let item2 of taskInfo.taskList"
              >
                <div class="col-auto">{{ item2.dateStart }}</div>
                <div class="col-auto">{{ item2.dateEnd }}</div>
                <div class="col-auto">
                  <b
                    ><a [href]="'/crm/task/' + item2.id">
                      {{ item2.value }}</a
                    ></b
                  >
                </div>
                <div class="col-auto">{{ item2.status }}</div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
