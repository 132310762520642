import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-title-modal-window',
  templateUrl: './title-modal-window.component.html',
})
export class TitleModalWindowComponent implements OnInit {
  @Input() title: string;

  constructor() {}

  ngOnInit(): void {}
}
