import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../../../../core/services/api.service';
import {RequestBody} from '../../../../core/interfaces/interfaces';

@Component({
  selector: 'app-dialog-summary',
  templateUrl: './dialog-gross-summary.component.html',
  styleUrls: ['./dialog-gross-summary.component.scss']
})
export class DialogGrossSummaryComponent implements OnInit {

  loading: any;
  loadSummary$: Observable<any>;

  constructor(
    private api: ApiService
  ) {
    this.loadSummary();
  }

  ngOnInit(): void {
  }

  loadSummary(): void {
    const q: RequestBody = {
      modelName: 'AnalyticsGross',
      calledMethod: 'getGrossSummaryLoad',
      methodProperties: {
        type: 1,
      }
    };

    this.loadSummary$ = this.api.post_jwt(q);

  }
}
