<mat-card-title>
  <div class="row justify-content-between mb-3">
    <div class="col-auto h2"><b>Добавить API Ключ</b></div>
    <div class="col-auto">
      <button type="button" mat-icon-button class="btn btn-primary btn-sm"
              matTooltip="Закрыть"
              matTooltipPosition="left"
              matTooltipClass="tooltip"
              matDialogClose>
        <img src="assets/img/icon/close/Icon_Close.svg" alt="close">
      </button>
    </div>
  </div>
</mat-card-title>

<mat-card-content>
  <form [formGroup]="form" (ngSubmit)="addNewDelivery()">
    <div class="row">
      <div class="col">
        <mat-form-field *ngIf="(deliveryList$|async) as deliveryList" style="width: 100%">
          <mat-label>Справочник доступных перевозчиков</mat-label>
          <mat-select formControlName="delivery">
            <mat-option *ngFor="let item of deliveryList" [value]="item.value">
              {{item.text}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('delivery').hasError('required')">
            Выберите перевозчика
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row my-3">
      <div class="col">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>API ключ<span class="text-danger">*</span></mat-label>
          <input matInput
                 type="text"
                 formControlName="value"
          />
          <mat-error *ngIf="form.get('value').hasError('required')">
            Введите API ключ
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row my-3">
      <div class="col-12 col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>Дата начала</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="dateStart">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="form.get('dateStart').hasError('required')">
            Выберите дату начала действия ключа
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>Дата окончания</mat-label>
          <input matInput [matDatepicker]="pickerEnd" formControlName="dateEnd">
          <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
          <mat-datepicker #pickerEnd></mat-datepicker>
          <mat-error *ngIf="form.get('dateStart').hasError('required')">
            Выберите дату окончания действия ключа
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row my-3">
      <div class="col">
        <mat-checkbox color="primary" formControlName="defaultDelivery">Установить по умолчанию</mat-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <button mat-button class="btn btn-success px-5">Добавить</button>
      </div>
    </div>
  </form>
</mat-card-content>
