import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CallList } from '../interface/cli';
import { OtherSpr, RequestBody } from '../../../../core/interfaces/interfaces';
import { ApiService } from '../../../../core/services/api.service';
import { filter } from 'rxjs/operators';

@Injectable()
export class ClsService {
  constructor(private api: ApiService) {}

  getCallList(dateStart: string, dateEnd: string): Observable<CallList> {
    const q: RequestBody = {
      modelName: 'VoIp',
      calledMethod: 'getCallList',
      methodProperties: {
        type: 2,
        dateStart,
        dateEnd,
      },
    };
    return this.api.post_jwt(q);
  }

  setCallStatus(idRow: number, value: number): any {
    const q: RequestBody = {
      modelName: 'VoIp',
      calledMethod: 'setCallStatus',
      methodProperties: {
        type: 2,
        idRow,
        value,
      },
    };
    return this.api.post_jwt(q);
  }
  onCreateNewOrder(hash: string, manager: number): any {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'onCreateNewOrderAboutIpNumber',
      methodProperties: {
        type: 2,
        hash,
        manager,
      },
    };
    return this.api.post_jwt(q);
  }

  loadUserList(): Observable<OtherSpr[]> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'loadUserListCall',
      methodProperties: {
        type: 2,
      },
    };
    return this.api.post_jwt(q);
  }

  loadOrderClientList(
    clientHash: string
  ): Observable<[{ label: string; value: number }]> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'loadOrderClientList',
      methodProperties: {
        type: 2,
        clientHash,
      },
    };
    return this.api.post_jwt(q);
  }

  setOrder(id, value): any {
    const q: RequestBody = {
      modelName: 'VoIp',
      calledMethod: 'setOrder',
      methodProperties: {
        type: 2,
        id,
        value,
      },
    };
    return this.api.post_jwt(q);
  }
}
