import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpecInfoDialogComponent } from './spec-info-dialog.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [SpecInfoDialogComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatTooltipModule,
    MatDialogModule,
  ],
})
export class SpecInfoDialogModule {}
