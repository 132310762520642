<lib-title-modal-window [title]="title"></lib-title-modal-window>

<mat-card-content>
  <div class="row my-3">
    <div class="col">Менеджер - {{ dialogData.item.manager }}</div>
    <div class="col">
      Заказ №
      <a
        [href]="'/crm/order-card/' + dialogData.item.orderNumber"
        target="_blank"
        class="link"
      >
        {{ dialogData.item.orderNumber }}</a
      >
    </div>
  </div>
  <form [formGroup]="formData" (ngSubmit)="addRandomProduct()">
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Наименование</mat-label>
          <input matInput formControlName="nomen" />
          <mat-error *ngIf="formData.get('nomen').hasError('required')">
            Заполните название товара
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Каталожный номер</mat-label>
          <input matInput formControlName="katalNumber" />
          <mat-error *ngIf="formData.get('katalNumber').hasError('required')">
            Укажите каталожный номер товара
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Бренд</mat-label>
          <input matInput formControlName="brand" />
          <mat-error *ngIf="formData.get('brand').hasError('required')">
            Заполните бренд товара
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-2">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Количество</mat-label>
          <mat-select formControlName="count">
            <mat-option *ngFor="let item of countList" [value]="+item">{{
              item
            }}</mat-option>
          </mat-select>
          <mat-error *ngIf="formData.get('count').hasError('required')">
            Укажите кол-во товара
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-slide-toggle
          color="primary"
          #showAnalog
          (ngModelChange)="setAnalog()"
          formControlName="checkAnalog"
          >{{
            showAnalog.checked ? "Укажите аналог" : "Добавить аналог"
          }}</mat-slide-toggle
        >
      </div>
    </div>
    <div class="row" *ngIf="showAnalog.checked">
      <div class="col">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Каталожный номер аналога</mat-label>
          <input matInput formControlName="katalNumberAnalog" />
          <mat-error
            *ngIf="formData.get('katalNumberAnalog').hasError('required')"
          >
            Укажите каталожный номер товара
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Бренд аналога</mat-label>
          <input matInput formControlName="brandAnalog" />
          <mat-error *ngIf="formData.get('brandAnalog').hasError('required')">
            Заполните бренд товара
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Количество аналога</mat-label>
          <mat-select formControlName="countAnalog">
            <mat-option *ngFor="let item of countList" [value]="+item">{{
              item
            }}</mat-option>
          </mat-select>
          <mat-error *ngIf="formData.get('countAnalog').hasError('required')">
            Укажите кол-во товара
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Цена закупки</mat-label>
          <input
            matInput
            formControlName="priceCost"
            (keyup)="setLoadSitePrice()"
          />
          <mat-error *ngIf="formData.get('priceCost').hasError('required')">
            Укажите цену закупки
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Цена отгрузки</mat-label>
          <input matInput formControlName="siteCost" />
          <mat-error *ngIf="formData.get('siteCost').hasError('required')">
            Укажите цену отгрузки
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field
          appearance="outline"
          class="w-100"
          *ngIf="loadProviderList$ | async as loadProviderList"
        >
          <mat-label>Поставщик</mat-label>
          <mat-select formControlName="zakazNumber">
            <mat-option
              *ngFor="let item of loadProviderList"
              [value]="+item.value"
              >{{ item.data }}</mat-option
            >
          </mat-select>
          <mat-error *ngIf="formData.get('zakazNumber').hasError('required')">
            Выберите поставщика товара
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-form-field
          appearance="outline"
          class="w-100"
          *ngIf="loadProviderList$ | async as loadProviderList"
        >
          <mat-label>Сроки поставки</mat-label>
          <input type="date" matInput formControlName="timeDelivery" />
          <mat-error *ngIf="formData.get('timeDelivery').hasError('required')">
            Выберите сроки поставки
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-6" *ngIf="+formData.get('zakazNumber').value === 99">
        <mat-form-field
          appearance="outline"
          class="w-100"
          *ngIf="loadProviderList$ | async as loadProviderList"
        >
          <mat-label>Комментарий о закупке товара</mat-label>
          <input matInput formControlName="commentPayment" />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Комментарий</mat-label>
          <textarea rows="4" matInput formControlName="comment"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="row justify-content-between">
      <div class="col-4">
        <button
          mat-button
          class="btn btn-success btn-block"
          *ngIf="+dialogData.item.actionId === 1"
        >
          Отправить
        </button>
      </div>
      <div class="col-4">
        <button
          type="button"
          mat-button
          class="btn btn-danger"
          (click)="setNotFound()"
        >
          Нет в наличии
        </button>
      </div>
    </div>
  </form>
</mat-card-content>
