<div class="container-fluid my-3">
  <div class="row">
    <div class="col">
      <mat-card>
        <mat-card-title>Финансовые результаты</mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content class="pt-3">
          <form [formGroup]="form" id="filterForm" (ngSubmit)="getOrderInfo()">
            <div class="row align-items-center">
              <div class="col-12 col-md-3">
                <mat-form-field>
                  <mat-label>Диапазон</mat-label>
                  <mat-date-range-input [rangePicker]="picker">
                    <input
                      matStartDate
                      formControlName="start"
                      placeholder="Дата начала"
                    />
                    <input
                      matEndDate
                      formControlName="end"
                      placeholder="Дата конца"
                    />
                  </mat-date-range-input>
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-date-range-picker
                    (closed)="getDynamicSpr()"
                    #picker
                  ></mat-date-range-picker>
                </mat-form-field>
              </div>
              <div class="col-auto">
                <button
                  mat-button
                  type="submit"
                  class="btn btn-success"
                  form="filterForm"
                >
                  Отправить
                </button>
              </div>

              <div class="col-auto">
                <mat-button-toggle-group
                  class="mat-btn-sm"
                  formControlName="dataType"
                >
                  <mat-button-toggle value="1">Час</mat-button-toggle>
                  <mat-button-toggle value="2">День</mat-button-toggle>
                  <mat-button-toggle value="3">Месяц</mat-button-toggle>
                  <mat-button-toggle value="4">Год</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="col-auto">
                <button
                  mat-button
                  type="button"
                  class="btn btn-sm btn-primary"
                  (click)="showSummary()"
                >
                  Сводка
                </button>
              </div>
              <div class="col-auto">
                <mat-form-field>
                  <mat-label>Тип даты</mat-label>
                  <mat-select
                    formControlName="orderDataType"
                    (change)="getDynamicSpr()"
                  >
                    <mat-option value="1">Дата заказа</mat-option>
                    <mat-option value="2">Дата взятия в работу</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-auto">
                <mat-slide-toggle
                  formControlName="typeProduct"
                  color="primary"
                  #typeProduct
                  (change)="getDynamicSpr()"
                  >{{
                    typeProduct.checked
                      ? "Товар добавленные клиентом"
                      : "Все товары"
                  }}</mat-slide-toggle
                >
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-3">
                <div class="row align-items-center">
                  <div class="col-1 text-center">
                    <input
                      type="radio"
                      form="filterForm"
                      formControlName="typeFilter"
                      value="1"
                    />
                  </div>
                  <div class="col-auto">
                    <mat-form-field>
                      <mat-label>Источник</mat-label>
                      <mat-select formControlName="source" multiple>
                        <mat-option
                          #allSelectedSource
                          (click)="toggleAllSource()"
                          [value]="'all'"
                          >Выбрать все
                        </mat-option>
                        <mat-divider></mat-divider>
                        <mat-option
                          *ngFor="let spr of sourceList"
                          [value]="spr.value"
                        >
                          {{ spr.text }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-3">
                <div class="row align-items-center">
                  <div class="col-1 text-center">
                    <input
                      type="radio"
                      form="filterForm"
                      formControlName="typeFilter"
                      value="2"
                    />
                  </div>
                  <div class="col-auto">
                    <mat-form-field>
                      <mat-label>Менеджеры</mat-label>
                      <mat-select formControlName="manager" multiple>
                        <mat-option
                          #allSelectedManager
                          (click)="toggleAllManager()"
                          [value]="'all'"
                          >Выбрать все
                        </mat-option>
                        <mat-divider></mat-divider>
                        <mat-option
                          *ngFor="let spr of managerList"
                          [value]="spr.value"
                        >
                          {{ spr.text }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-3">
                <div class="row align-items-center">
                  <div class="col-1 text-center">
                    <input
                      type="radio"
                      form="filterForm"
                      formControlName="typeFilter"
                      value="3"
                    />
                  </div>
                  <div class="col-auto">
                    <mat-form-field>
                      <mat-label>Автомобиль</mat-label>
                      <mat-select formControlName="car" multiple>
                        <mat-option
                          #allSelectedCar
                          (click)="toggleAllCar()"
                          [value]="'all'"
                          >Выбрать все</mat-option
                        >
                        <mat-divider></mat-divider>
                        <mat-option
                          *ngFor="let spr of carList"
                          [value]="spr.value"
                        >
                          {{ spr.text }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-3">
                <div class="row align-items-center">
                  <div class="col-1 text-center">
                    <input
                      type="radio"
                      form="filterForm"
                      formControlName="typeFilter"
                      value="4"
                    />
                  </div>
                  <div class="col-auto">
                    <mat-form-field>
                      <mat-label>Способ обращения</mat-label>
                      <mat-select formControlName="formSale" multiple>
                        <mat-option
                          #allSelectedForm
                          (click)="toggleAllForm()"
                          [value]="'all'"
                          >Выбрать все</mat-option
                        >
                        <mat-divider></mat-divider>
                        <mat-option
                          *ngFor="let spr of formList"
                          [value]="spr.value"
                        >
                          {{ spr.text }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-3">
                <div class="row align-items-center">
                  <div class="col-1 text-center">
                    <input
                      type="radio"
                      form="filterForm"
                      formControlName="typeFilter"
                      value="5"
                    />
                  </div>
                  <div class="col-auto">
                    <mat-form-field>
                      <mat-label>Товарная группа</mat-label>
                      <mat-select formControlName="prodGroup" multiple>
                        <mat-option
                          #allSelectedGroup
                          (click)="toggleAllGroup()"
                          [value]="'all'"
                          >Выбрать все
                        </mat-option>
                        <mat-divider></mat-divider>
                        <mat-option
                          *ngFor="let spr of prodGroupList"
                          [value]="spr.value"
                        >
                          {{ spr.text }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-3">
                <div class="row align-items-center">
                  <div class="col-1 text-center">
                    <input
                      type="radio"
                      form="filterForm"
                      formControlName="typeFilter"
                      value="6"
                    />
                  </div>
                  <div class="col-auto">
                    <mat-form-field>
                      <mat-label>Способ оплаты</mat-label>
                      <mat-select formControlName="payment" multiple>
                        <mat-option
                          #allSelectedPayment
                          (click)="toggleAllPayment()"
                          [value]="'all'"
                          >Выбрать все
                        </mat-option>
                        <mat-divider></mat-divider>
                        <mat-option
                          *ngFor="let spr of paymentList"
                          [value]="spr.value"
                        >
                          {{ spr.text }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-3">
                <div class="row align-items-center">
                  <div class="col-1 text-center">
                    <input
                      type="radio"
                      form="filterForm"
                      formControlName="typeFilter"
                      value="7"
                    />
                  </div>
                  <div class="col-auto">
                    <mat-form-field>
                      <mat-label>Способ доставки</mat-label>
                      <mat-select formControlName="delivery" multiple>
                        <mat-option
                          #allSelectedDelivery
                          (click)="toggleAllDelivery()"
                          [value]="'all'"
                          >Выбрать все
                        </mat-option>
                        <mat-divider></mat-divider>
                        <mat-option
                          *ngFor="let spr of deliveryList"
                          [value]="spr.value"
                        >
                          {{ spr.text }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-3">
                <div class="row align-items-center">
                  <div class="col-1 text-center">
                    <input
                      type="radio"
                      form="filterForm"
                      formControlName="typeFilter"
                      value="8"
                    />
                  </div>
                  <div class="col-auto">
                    <mat-form-field>
                      <mat-label>Бренд</mat-label>
                      <mat-select formControlName="brand" multiple>
                        <mat-option
                          #allSelectedBrand
                          (click)="toggleAllBrand()"
                          [value]="'all'"
                          >Выбрать все
                        </mat-option>
                        <mat-divider></mat-divider>
                        <mat-option
                          *ngFor="let spr of brandList"
                          [value]="spr.value"
                        >
                          {{ spr.text }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="row my-3">
    <div class="col">
      <p-table
        *ngIf="topList | async as topList"
        [columns]="topList.headerList"
        [value]="topList.orderList"
        [loading]="loading"
        autoLayout="true"
        selectionMode="single"
        styleClass="p-datatable-gridlines p-datatable-sm"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th
              *ngFor="let col of columns"
              style="font-weight: 400; font-size: 12px"
            >
              {{ col.header }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td
              *ngFor="let col of columns; let index = index"
              style="font-weight: 300; font-size: 12px"
              (click)="showMoreInfoTop(index + 1, rowData)"
            >
              {{ rowData[col.field] }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <div class="row my-3">
    <div class="col">
      <p-table
        [columns]="cols"
        [value]="orderList"
        [loading]="loading"
        [rowsPerPageOptions]="[10, 25, 50, 100]"
        [paginator]="true"
        currentPageReportTemplate="Элементов на странице ({first} - {last}) з {totalRecords} "
        [filterDelay]="0"
        autoLayout="true"
        [rows]="10"
        selectionMode="single"
        styleClass="p-datatable-gridlines p-datatable-sm"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th
              *ngFor="let col of columns"
              [pSortableColumn]="col.field"
              style="font-weight: 400; font-size: 12px"
            >
              <p-sortIcon [field]="col.field"></p-sortIcon>
              {{ col.header }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td
              *ngFor="let col of columns"
              style="font-weight: 300; font-size: 12px"
              (click)="showMoreInfo(col.field, rowData)"
            >
              <div [ngSwitch]="col.field">
                <div *ngSwitchCase="'entry'">
                  {{
                    srv.roundNumber(
                      (rowData["allSale"] * 100) / footerInfo.allSale
                    )
                  }}
                </div>
                <div *ngSwitchCase="'middleSale'">
                  {{ rowData["middleSale"] }} | {{ rowData["middleSaleOne"] }} /
                  {{ rowData["middleSaleMore"] }}
                </div>
                <div *ngSwitchCase="'allSale'">
                  {{ rowData[col.field] }}|<span
                    matTooltip="Одинарный заказ"
                    matTooltipClass="tooltip"
                    style="color: #2f80ec"
                  >
                    {{ rowData["oneProd"] }}
                    ({{
                      (rowData["oneProd"] * 100) / rowData[col.field]
                        | number: "1.0-0"
                    }}
                    %) </span
                  >/<span
                    matTooltip="Комплексный заказ"
                    matTooltipClass="tooltip"
                    style="color: #27ae60"
                  >
                    {{ rowData["moreProd"] }}
                    ({{
                      (rowData["moreProd"] * 100) / rowData[col.field]
                        | number: "1.0-0"
                    }}
                    %)
                  </span>
                  |<span
                    matTooltip="Допродажа"
                    matTooltipClass="tooltip"
                    style="color: #2f80ec"
                  >
                    {{ rowData["getProdDopSuccessSale"] }}
                    ({{
                      (rowData["getProdDopSuccessSale"] * 100) /
                        rowData[col.field] | number: "1.0-0"
                    }}
                    %)</span
                  >
                </div>
                <div *ngSwitchCase="'grossSale'">
                  {{ rowData[col.field] }}
                  |
                  <span
                    matTooltip="Допродажа"
                    matTooltipClass="tooltip"
                    style="color: #2f80ec"
                  >
                    {{ rowData["getProdDopSuccessGross"] }}
                    ({{
                      (rowData["getProdDopSuccessGross"] * 100) /
                        rowData[col.field] | number: "1.0-0"
                    }}
                    %)</span
                  >
                </div>
                <div *ngSwitchDefault>
                  {{ rowData[col.field] }}
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr [class]="footerInfo.class">
            <td *ngIf="footerInfo.colspan" [colSpan]="footerInfo.colspan">
              Итого
            </td>
            <td>
              {{ footerInfo.allSale }} | {{ footerInfo.saleOne }}({{
                (footerInfo.saleOne * 100) / footerInfo.allSale
                  | number: "1.0-0"
              }}
              %) / {{ footerInfo.saleMore }}({{
                (footerInfo.saleMore * 100) / footerInfo.allSale
                  | number: "1.0-0"
              }}
              %) |
              <span
                matTooltip="Допродажа"
                matTooltipClass="tooltip"
                style="color: #2f80ec"
              >
                {{ footerInfo.sumSaleDop }}
                ({{
                  (+footerInfo.sumSaleDop * 100) / footerInfo.allSale
                    | number: "1.0-0"
                }}
                %)</span
              >
            </td>

            <td>
              {{ footerInfo.middleSale }}| {{ footerInfo.middleSaleOne }} /
              {{ footerInfo.middleSaleMore }}
            </td>
            <td *ngIf="cols && cols[3].field === 'grossSale'">
              {{ footerInfo.grossSale }} |
              <span
                matTooltip="Допродажа"
                matTooltipClass="tooltip"
                style="color: #2f80ec"
              >
                {{ +footerInfo.sumGrossDop }}
                ({{
                  (+footerInfo.sumGrossDop * 100) / footerInfo.grossSale
                    | number: "1.0-0"
                }}
                %)</span
              >
            </td>
            <td *ngIf="cols && cols[3].field === 'grossSale'">
              {{ footerInfo.conv1 }}
            </td>
            <td>{{ footerInfo.entry }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <div class="row my-3">
    <div
      class="col-12 col-md-12"
      style="overflow-y: scroll; height: auto !important"
    >
      <p-chart type="pie" [data]="pieData"></p-chart>
    </div>
    <div class="col-12 col-md-6" *ngFor="let item of lineDataArr">
      <mat-card class="my-2">
        <mat-label>{{ item.labelChart }}</mat-label>
        <p-chart type="line" [data]="item"></p-chart>
      </mat-card>
    </div>
  </div>
</div>
