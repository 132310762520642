<lib-title-modal-window [title]="title"></lib-title-modal-window>

<mat-card-content *ngIf="loadProtectionList$ | async as loadProtectionList">
  <table class="table table-bordered table-hover table-striped">
    <thead>
      <tr>
        <th>Модель</th>
        <th>Что защищает</th>
        <th>Примечание</th>
        <th style="width: 6rem">Стоимость</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of loadProtectionList">
        <td class="px-1">{{ item.nomen }}</td>
        <td class="px-2">{{ item.deff }}</td>
        <td class="px-1">{{ item.comment }}</td>
        <td class="px-2 text-primary">
          <b>{{ item.cost }} грн.</b>
        </td>
        <td class="text-center p-2">
          <a
            [href]="'/crm/product/' + item.katalNumber + '_' + item.brand"
            type="button"
            mat-button
            class="btn btn-success btn-block"
          >
            Открыть
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</mat-card-content>
