<div class="container-fluid my-3">
  <div class="row">
    <div class="col">
      <mat-card>
        <mat-card-title>Аналитика наличия товаров</mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content class="pt-3">
          <form [formGroup]="form" id="filterForm" (ngSubmit)="getProdInfo()">
            <div class="row align-items-center">

              <div class="col-auto">
                <button mat-button type="submit" class="btn btn-success" form="filterForm">Отправить</button>
              </div>

            </div>
<!--            <div class="row">
              <div class="col-12 col-md-3">
                <div class="row align-items-center">
                  <div class="col-1 text-center">
                    <input type="radio" form="filterForm" formControlName="typeFilter" value="1">
                  </div>
                  <div class="col-auto">
                    <mat-form-field *ngIf="(provider| async) as providerList">
                      <mat-label>Поставщик</mat-label>
                      <mat-select formControlName="provider" multiple>
                        <mat-option #allSelectedProvider (click)="toggleAllprovider()" [value]="'all'">Выбрать все
                        </mat-option>
                        <mat-divider></mat-divider>
                        <mat-option *ngFor="let spr of providerList" [value]="spr.value">
                          {{spr.text}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
&lt;!&ndash;              <div class="col-12 col-md-3">
                <div class="row  align-items-center">
                  <div class="col-1 text-center">
                    <input type="radio" form="filterForm" formControlName="typeFilter" value="2">
                  </div>
                  <div class="col-auto">
                    <mat-form-field>
                      <mat-label>Менеджеры</mat-label>
                      <mat-select formControlName="manager" multiple>
                        <mat-option #allSelectedManager (click)="toggleAllManager()" [value]="'all'">Выбрать все
                        </mat-option>
                        <mat-divider></mat-divider>
                        <mat-option *ngFor="let spr of managerList" [value]="spr.value">
                          {{spr.text}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-3">
                <div class="row align-items-center">
                  <div class="col-1 text-center">
                    <input type="radio" form="filterForm" formControlName="typeFilter" value="3">
                  </div>
                  <div class="col-auto">
                    <mat-form-field>
                      <mat-label>Автомобиль</mat-label>
                      <mat-select formControlName="car" multiple>
                        <mat-option #allSelectedCar (click)="toggleAllCar()" [value]="'all'">Выбрать все</mat-option>
                        <mat-divider></mat-divider>
                        <mat-option *ngFor="let spr of carList" [value]="spr.value">
                          {{spr.text}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-3">
                <div class="row align-items-center">
                  <div class="col-1 text-center">
                    <input type="radio" form="filterForm" formControlName="typeFilter" value="4">
                  </div>
                  <div class="col-auto">
                    <mat-form-field>
                      <mat-label>Способ обращения</mat-label>
                      <mat-select formControlName="formSale" multiple>
                        <mat-option #allSelectedForm (click)="toggleAllForm()" [value]="'all'">Выбрать все</mat-option>
                        <mat-divider></mat-divider>
                        <mat-option *ngFor="let spr of formList" [value]="spr.value">
                          {{spr.text}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-3">
                <div class="row align-items-center">
                  <div class="col-1 text-center">
                    <input type="radio" form="filterForm" formControlName="typeFilter" value="5">
                  </div>
                  <div class="col-auto">
                    <mat-form-field>
                      <mat-label>Товарная группа</mat-label>
                      <mat-select formControlName="prodGroup" multiple>
                        <mat-option #allSelectedGroup (click)="toggleAllGroup()" [value]="'all'">Выбрать все
                        </mat-option>
                        <mat-divider></mat-divider>
                        <mat-option *ngFor="let spr of prodGroupList" [value]="spr.value">
                          {{spr.text}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

              </div>
              <div class="col-12 col-md-3">
                <div class="row align-items-center">
                  <div class="col-1 text-center">
                    <input type="radio" form="filterForm" formControlName="typeFilter" value="6">
                  </div>
                  <div class="col-auto">
                    <mat-form-field>
                      <mat-label>Способ оплаты</mat-label>
                      <mat-select formControlName="payment" multiple>
                        <mat-option #allSelectedPayment (click)="toggleAllPayment()" [value]="'all'">Выбрать все
                        </mat-option>
                        <mat-divider></mat-divider>
                        <mat-option *ngFor="let spr of paymentList" [value]="spr.value">
                          {{spr.text}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

              </div>
              <div class="col-12 col-md-3">
                <div class="row align-items-center">
                  <div class="col-1 text-center">
                    <input type="radio" form="filterForm" formControlName="typeFilter" value="7">
                  </div>
                  <div class="col-auto">
                    <mat-form-field>
                      <mat-label>Способ доставки</mat-label>
                      <mat-select formControlName="delivery" multiple>
                        <mat-option #allSelectedDelivery (click)="toggleAllDelivery()" [value]="'all'">Выбрать все
                        </mat-option>
                        <mat-divider></mat-divider>
                        <mat-option *ngFor="let spr of deliveryList" [value]="spr.value">
                          {{spr.text}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

              </div>
              <div class="col-12 col-md-3">
                <div class="row align-items-center">
                  <div class="col-1 text-center">
                    <input type="radio" form="filterForm" formControlName="typeFilter" value="8">
                  </div>
                  <div class="col-auto">
                    <mat-form-field>
                      <mat-label>Бренд</mat-label>
                      <mat-select formControlName="brand" multiple>
                        <mat-option #allSelectedBrand (click)="toggleAllBrand()" [value]="'all'">Выбрать все
                        </mat-option>
                        <mat-divider></mat-divider>
                        <mat-option *ngFor="let spr of brandList" [value]="spr.value">
                          {{spr.text}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

              </div>&ndash;&gt;
            </div>-->
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>


  <div class="row my-3" *ngIf="(prod|async) as prod">
    <div class="col">
      <p-table [columns]="prod.cols"
               [value]="prod.rows"
               [rowsPerPageOptions]="[10,25,50,100]"
               [paginator]="true"
               currentPageReportTemplate="Элементов на странице ({first} - {last}) з {totalRecords} "
               [filterDelay]="0"
               autoLayout="true"
               [rows]="10"
               selectionMode="single"
               styleClass="p-datatable-gridlines p-datatable-sm">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th
              *ngFor="let col of columns"
              [pSortableColumn]="col.field"
              style="font-weight: 400;font-size: 12px">
              <p-sortIcon [field]="col.field"></p-sortIcon>
              {{col.header}}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td
              *ngFor="let col of columns;"
              style="font-weight: 300;font-size: 12px"
            >
              {{rowData[col.field]}}
            </td>

          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
