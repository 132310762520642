import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderInfoComponent } from './header-info.component';
import { TitleModalWindowModule } from '../../shared/title-modal-window/title-modal-window.module';

@NgModule({
  declarations: [HeaderInfoComponent],
  imports: [CommonModule, TitleModalWindowModule],
})
export class HeaderInfoModule {}
