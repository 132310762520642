import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../core/services/api.service';
import { Observable } from 'rxjs';
import {
  OtherSpr,
  RequestBody,
} from '../../../../../core/interfaces/interfaces';

@Injectable()
export class RraService {
  constructor(private api: ApiService) {}

  loadRandomProductRequestInfo(id): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'loadRandomProductRequestInfo',
      methodProperties: {
        type: 1,
        id,
      },
    };
    return this.api.post_jwt(q);
  }

  loadProviderList(): Observable<OtherSpr[]> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'loadProviderList',
      methodProperties: {
        type: 1,
      },
    };
    return this.api.post_jwt(q);
  }

  getNewSiteCost(priceCost): Observable<any> {
    const q: RequestBody = {
      modelName: 'PaymentProduct',
      calledMethod: 'getNewSiteCost',
      methodProperties: {
        type: 1,
        priceCost,
      },
    };
    return this.api.post_jwt(q);
  }

  setApproveProduct(obj: any): Observable<any> {
    const q: RequestBody = {
      modelName: 'PaymentProduct',
      calledMethod: 'setApproveProduct',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  setNotFound(obj: any): Observable<any> {
    const q: RequestBody = {
      modelName: 'PaymentProduct',
      calledMethod: 'setNotFound',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }
}
