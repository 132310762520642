import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RandomRequestListComponent } from './random-request-list/random-request-list.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [RandomRequestListComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatTooltipModule,
    MatDialogModule,
  ],
})
export class RandomRequestListModule {}
